import React from 'react';
import { Center, Image } from '@chakra-ui/react';
import Logo from '../../assets/image/logo.png';
import Products from '../Products';

const MHeader: React.FC = () => {
    return (
        <>
            <Center>
                <Image src={Logo} height='30px' />
                <Center>
                    <Products />
                </Center>
            </Center>
        </>
    );
}

export default MHeader;
