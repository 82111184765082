import { Box, Container, Flex, Grid, GridItem, HStack, Image, Show, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { FooterLink } from './FooterLink';
import FooterHeading from './FooterHeading';
import Logo from '../../assets/image/logo.png';

const MEDIUM_URL = 'https://medium.com/@tagmi';
// export const TWITTER_URL = 'https://twitter.com/tagmiofficial';
export const FAQURL = `${MEDIUM_URL}/tagmi-faq-cfa459d1f8a5`;
// export const DC_URL = 'http://discord.gg/PaACyGYatV';
export const DOCS_URL = 'https://tagmi.gitbook.io/docs/';

export const Footer: React.FC = () => (
    <Box as='footer' bgColor='white' py={{ base: '48px', lg: '72px' }} mt={5}>
        <Container maxW='1680px' px={{ base: '20px', md: '32px', lg: '72px' }}>
            <Flex direction='column' gap='50px'>
                <Grid
                    gap={{ base: '46px', lg: '64px' }}
                    templateColumns={{ base: 'repeat(2, 1fr)', lg: 'repeat(5, 1fr)' }}>
                    <GridItem colSpan={{ base: 2, lg: 1 }} w='100%'>
                        <HStack align='start' gap='16px'>
                            <Text
                                as='span'
                                fontWeight={400}
                                fontSize='12px'
                                lineHeight={1.625}
                                color='gray.500'>
                                &copy; {new Date().getFullYear()} TAGMI
                            </Text>
                            <Image src={Logo} height='20px'/>
                        </HStack>
                    </GridItem>
                    <Show above='lg'>
                        <GridItem w='100%' />
                    </Show>
                    <GridItem w='100%'>
                        <VStack align='start' gap='16px'>
                            <FooterHeading>About TAGMI</FooterHeading>
                            <FooterLink href={FAQURL} isExternal>
                                FAQ
                            </FooterLink>
                            <FooterLink href={MEDIUM_URL} isExternal>
                                Medium
                            </FooterLink>
                        </VStack>
                    </GridItem>
                    {/*<GridItem w='100%'>*/}
                    {/*    <VStack align='start' gap='16px'>*/}
                    {/*        <FooterHeading>Community</FooterHeading>*/}
                    {/*        <FooterLink href={TWITTER_URL} isExternal>*/}
                    {/*            Twitter*/}
                    {/*        </FooterLink>*/}
                    {/*    </VStack>*/}
                    {/*</GridItem>*/}
                </Grid>
            </Flex>
        </Container>
    </Box>
);
