import { Flex } from '@chakra-ui/react';
import React from 'react';
import ViewContainer from './ViewContainer';

const CenteredViewContainer: React.FC<{ children: any }> = ({ children }) => (
    <ViewContainer>
        <Flex w='100%' h='100%' flexDirection='column' alignItems='center' alignContent='center'>
            { children }
        </Flex>
    </ViewContainer>
);

export default CenteredViewContainer;
