import { walletProvider, WalletProviderButton } from './Buttons/WalletProviderButton';
import { Flex, Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import React, { useContext } from 'react';
import { Web3Context } from '../providers/Web3Provider';

const ConnectWallet: React.FC<{ onClose?: () => void }> = ({ onClose }) => {
    const { connectors, connectWallet } = useContext(Web3Context);

    const onConnect = async (connectorId: string) => {
        const connector = connectors.find(c => c.id === connectorId);
        if (!connector) {
            return;
        }
        try {
            await connectWallet(connector);
            if (onClose) {
                onClose();
            }
        } catch (e: any)  {
            console.log(e, typeof e, e?.message, Object.keys(e))
        }
    }

    return (
        <Flex maxWidth={280} align='middle' textAlign='center' direction='column' gap='16px'>
            {connectors.map(connector => (
                <WalletProviderButton
                    key={connector.id}
                    loadingText={connector.name}
                    onClick={() => onConnect(connector.id)}
                    provider={walletProvider[connector.name]}
                    disabled={!connector.ready}>
                    { connector.name }
                    { !connector.ready && ' (unsupported)' }
                </WalletProviderButton>
            ))}

            <Link
                isExternal
                href='https://ethereum.org/en/wallets/find-wallet/'
                fontWeight='bold'
                mt={10}
                target='_blank'>
                Learn about wallets here <ExternalLinkIcon />
            </Link>
        </Flex>
    )
}

export default ConnectWallet;
