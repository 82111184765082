import React, { useCallback, useState } from 'react';
import { Button, Flex, Icon, Input, Modal, ModalBody, ModalContent, ModalOverlay, useToast } from '@chakra-ui/react';
import { MdKey } from 'react-icons/md';

const wrongOptions = [
    'Nope',
    'Naaah',
    'Try again chief',
    'Almost got it',
    'You are getting there',
    'Maybe another time',
    'Are we going to be here all day?',
    'For the love of God. Ask someone.',
    'Stop. Get help',
    'Maybe ask ChatGPT',
    'Ok that\'s it I am done. You only get generic messages from now on.',
    'This was not correct.',
    'This was not correct.',
    'This was not correct.',
    'This was not correct.',
    'This was not correct.',
    'Are you still here?',
    'I am seriously done',
    'No'
];

const PasswordModal: React.FC = () => {
    const toast = useToast();
    const [optionC, setOptionC] = useState<number>(0);
    const [v, setV] = useState<string>('');
    const [modalOpen, setModalOpen] = useState<boolean>(!localStorage.getItem('knowspw'));
    const pw = 'betmore';

    const onEnter = useCallback(() => {
        if (v === pw) {
            localStorage.setItem('knowspw', 'true')
            setModalOpen(false);
        } else {
            toast({
                title: wrongOptions[optionC],
                duration: 1200,
                isClosable: false,
                status: 'error',
            });
            if (optionC < wrongOptions.length - 1) {
                setOptionC(optionC + 1)
            }
        }
        console.log(modalOpen)
    }, [v, optionC]);

    return (
        <Modal isCentered isOpen={false} size='lg' onClose={() => { return; }}>
            <ModalOverlay bg='blackAlpha.100' backdropFilter='blur(40px) hue-rotate(90deg)' />
            <ModalContent p={0}>
                <ModalBody p={0}>
                    <Flex direction='column'>
                        <Input
                            type='password'
                            value={v}
                            borderRadius={0}
                            onChange={e => setV(e.target.value)} />
                        <Button width='full' bg='gray.900' color='gray.700' borderRadius={0} onClick={onEnter}>
                            <Icon as={MdKey} w='30px' h='30px' ml={2}/>
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default PasswordModal;
