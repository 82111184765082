import { Link, LinkProps } from '@chakra-ui/react';
import React from 'react';

export interface FooterLinkProps extends LinkProps {
    to?: string;
}

export const FooterLink: React.FC<FooterLinkProps> = ({ children, ...props }) => (
    <Link
        _hover={{
            color: 'gray.500',
            textDecoration: 'underline'
        }}
        color='gray.500'
        fontSize='14px'
        {...props}>
        {children}
    </Link>
);
