import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button, Center,
    Heading, Spinner,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr
} from '@chakra-ui/react';
import { Web3Context } from '../../../providers/Web3Provider';
import { AppRoute, IEscrow } from '../../../helpers/constants';
import ArbitrateEntry from './ArbitrateEntry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Arbitrate: React.FC = () => {
    const {
        appConfig,
        walletAddress,
        isConnected,
        signer,
        contracts: { escrow },
        wrongChain
    } = useContext(Web3Context);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [escrows, setEscrows] = useState<IEscrow[]>([]);

    const loadEscrows = useCallback(async (address: string): Promise<void> => {
        if (!signer || !escrow || wrongChain) {
            return;
        }
        setLoading(true);
        const escrowIds: number[] = await escrow.getEscrowsForAddress(address);
        const scrsPromises: Promise<any>[] = [];
        escrowIds.forEach(id => scrsPromises.push(escrow.escrows(id)));
        const results = await Promise.all(scrsPromises);
        setEscrows(results);
        setLoading(false);
    }, [signer, escrow, wrongChain]);

    useEffect(() => {
        if (walletAddress) {
            loadEscrows(walletAddress);
        }
    }, [appConfig, walletAddress, loadEscrows, wrongChain]);

    const escrowsToArbitrate = useMemo(() => {
        if (escrows.length) {
            const escrowsToArbitrate: IEscrow[] = [];
            escrows.forEach((escrow: IEscrow) => {
                const partyArbitrator = escrow.partyArbitrator;
                const closed = escrow.closed;
                const started = escrow.started;
                const isArbitrator =
                    (partyArbitrator === walletAddress)
                    && !closed
                    && started;
                if (isArbitrator) {
                    escrowsToArbitrate.push(escrow);
                }
            });

            return escrowsToArbitrate;
        }
        return [];
    }, [isConnected, walletAddress, escrows]);

    return (
        <Stack as={Box} textAlign='center' spacing={{ base: 8, md: 14 }} py={{ base: 20, md: 30 }} width='100%'>
            { loading && <Center><Spinner /></Center> }
            { !isConnected &&
                <>
                    <Heading
                        fontWeight={400}
                        fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                        lineHeight='100%'
                        color='gray.700'>
                        Make money from <br />
                        <Text as='span' color='gray.700'>
                            arbitrating wagers
                        </Text>
                    </Heading>
                    <Text color='gray.400'>
                        Decide the outcome of a wager and get a percentage for the total as payment.
                    </Text>

                    <Button
                        bgColor='gray.800'
                        color='gray.50'
                        fontSize='18px'
                        px={5}
                        py={4}
                        mt={5}
                        size='xl'
                        onClick={() => navigate(AppRoute.CONNECT)}
                        _hover={{ bg: 'gray.700' }}>
                        Connect wallet <Box ml='10px'><FontAwesomeIcon icon={faArrowRight} /></Box>
                    </Button>
                </>
            }
            {!loading && !escrowsToArbitrate.length && isConnected && (
                <Text as='span' color='rose.400'>
                    You have not been assigned anything to arbitrate yet
                </Text>
            )}
            {escrowsToArbitrate.length > 0 && !loading && (
                <TableContainer>
                    <Table variant='striped' colorScheme='blue' display={'table'}>
                        <Thead>
                            <Tr>
                                <Th>TAG #</Th>
                                <Th>Description</Th>
                                <Th>Vote</Th>
                                <Th>Your fee</Th>
                                <Th>For</Th>
                                <Th>Against</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {escrowsToArbitrate.map(escrow =>
                                <ArbitrateEntry key={escrow.escrowId.toNumber()} chainEscrow={escrow} />
                            )}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Th>Id</Th>
                                <Th>Description</Th>
                                <Th>You Earn</Th>
                                <Th>For</Th>
                                <Th>Against</Th>
                                <Th>Vote</Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
            )}
        </Stack>
    );
};

export default Arbitrate;
