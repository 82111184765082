import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Web3Context } from '../../../providers/Web3Provider';
import { Box, Button, Flex, SimpleGrid, Stack, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import moment from 'moment/moment';
import { ethers } from 'ethers';
import { erc20ABI, useNetwork } from 'wagmi';
import { IOTCEscrow, ZERO_ADDY } from '../../../helpers/constants';
import getCurrencyDecimalsAndSymbol from '../../../helpers/getCurrencyDecimalsAndSymbol';
import formatDt from '../../../helpers/format/formatDt';
import { OTCViewCategories } from '../index';

interface IOtcEntryProps {
    otcEscrow: IOTCEscrow;
    category: string;
}

const OtcListEntry: React.FC<IOtcEntryProps> = ({ otcEscrow, category }) => {
    const { chain } = useNetwork()
    const toast = useToast();
    const { walletAddress, signer, contracts: { escrowOtc } } = useContext(Web3Context);

    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const [forDeposit, setForDeposit] = useState<string>('');
    const [againstDeposit, setAgainstDeposit] = useState<string>('');
    const [ownerPro, setOwnerPro] = useState<string | undefined>(undefined);
    const [ownerAgainst, setOwnerAgainst] = useState<string | undefined>(undefined);

    useEffect(() => {
        setParties();
    }, [otcEscrow, escrowOtc]);

    const setParties = async () => {
        if (!escrowOtc) {
            return;
        }
        setOwnerPro(otcEscrow.partyA);
        setOwnerAgainst(otcEscrow.partyB);
    }

    const deposit = async (tagEscrow: IOTCEscrow) => {
        if (!signer || !escrowOtc) {
            return;
        }
        setBtnLoading(true);
        const currencyAddress = tagEscrow.pendingAssetB.currency;
        const currencyAmount = tagEscrow.pendingAssetB.amount;
        const payingWithGas = currencyAddress === ZERO_ADDY;

        const token = new ethers.Contract(currencyAddress, erc20ABI, signer);
        if (!payingWithGas) {
            const allowance = await token.allowance(walletAddress, escrowOtc.address);
            if (allowance < currencyAmount) {
                await (
                    await token.approve(escrowOtc.address, currencyAmount)
                ).wait();
            }
        }
        try {
            const escrowId = tagEscrow.escrowId.toNumber();
            const paymentParams = { value: !payingWithGas ? 0 : currencyAmount };
            const tx = await escrowOtc.depositFunds(escrowId, { ...paymentParams });
            await tx.wait();
            setBtnLoading(false);
            window.location.reload();
        } catch (e: any) {
            toast({
                title: e?.reason ?? e?.data?.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setBtnLoading(false);
        }
    };

    const reclaimFunds = async (escrowId: number, escrowContract: ethers.Contract) => {
        if (!signer || !escrowContract) {
            return;
        }
        setBtnLoading(true);
        const tx = await escrowContract.reclaimFunds(escrowId);
        await tx.wait();
        setBtnLoading(false);
        window.location.reload();
    };

    useEffect(() => {
        loadEscrowDetails();
    }, [walletAddress, escrowOtc, signer, otcEscrow]);

    const isProSide = useMemo(() => walletAddress === ownerPro, [ownerPro]);

    const loadEscrowDetails = async () => {
        if (!escrowOtc || !signer || !chain) {
            return;
        }
        const escrowId = otcEscrow.escrowId;
        const assetsA = await escrowOtc.escrowAssetsA(escrowId);
        const assetsB = otcEscrow.pendingAssetB;
        const currencyA = new ethers.Contract(assetsA.currency, erc20ABI, signer);
        const currencyB = new ethers.Contract(assetsB.currency, erc20ABI, signer);
        const { decimals: decimalsA, symbol: symbolA } = await getCurrencyDecimalsAndSymbol(currencyA, chain);
        const { decimals: decimalsB, symbol: symbolB } = await getCurrencyDecimalsAndSymbol(currencyB, chain);
        const amountA = ethers.utils.formatUnits(`${assetsA.amount}`, decimalsA);
        const amountB = ethers.utils.formatUnits(`${assetsB.amount}`, decimalsB);
        setForDeposit(amountA + '\n' + symbolA);
        setAgainstDeposit(amountB + '\n' + symbolB)
    }

    const wrapLoading = async (method: () => void): Promise<void> => {
        setBtnLoading(true);
        try {
            await method();
        } catch (e: any) {
            toast({
                title: e?.reason,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setBtnLoading(false);
        }
    }

    const btnProps = {
        mt: 10,
        w: 'full',
        bg: '#25273D',
        color: 'white',
        boxShadow: '0 5px 20px 0px rgb(72 187 120 / 43%)',
        _hover: { bg: 'tagmi.logo' },
        _focus: { bg: 'tagmi.logo' },
    };

    const getButton = () => {
        if (!escrowOtc || !ownerPro) {
            return;
        }
        const createTimeUnix = moment.unix(otcEscrow.createTime.toNumber());
        const undeterminedPast3Days = moment().isAfter(createTimeUnix.add(72, 'h'));
        const escrowId = otcEscrow.escrowId.toNumber();
        const canDeposit = category === OTCViewCategories.OPEN && walletAddress === ownerAgainst;
        const canReclaim = category === OTCViewCategories.OPEN && undeterminedPast3Days;

        return (
            <>
                {canDeposit && (
                    <Button
                        {...btnProps}
                        isLoading={btnLoading}
                        onClick={() => deposit(otcEscrow)}>
                        Approve and Deposit
                    </Button>
                )}
                {canReclaim && (
                    <Button
                        {...btnProps}
                        isLoading={btnLoading}
                        onClick={() => wrapLoading(() => reclaimFunds(escrowId, escrowOtc))}>
                        Reclaim funds
                    </Button>
                )}
            </>
        )
    }

    return (
        <Flex py={4} borderRadius='xl' w='300px'>
            <Box maxW='330px' w='full' bg='stefanos.1' boxShadow='2xl' rounded='xl' overflow='hidden'>
                <Stack
                    textAlign='center'
                    p={3}
                    color={useColorModeValue('gray.800', 'white')}
                    direction='column'
                    spacing={1}>
                    <Text
                        alignItems='top'
                        color='gray.800'
                        fontWeight='bold'
                        fontSize='2xl'
                        px={2}>
                        SWAP#{ otcEscrow.escrowId.toNumber() }
                    </Text>
                </Stack>

                <Box bg='stefanos.1' px={6} py={10}>
                    <SimpleGrid columns={2} spacing={4}>
                        <Text>Counterparty</Text>
                        <Text color='gray.800' fontWeight='bold'>
                            {isProSide ? ownerAgainst : ownerPro}
                        </Text>

                        <Text>SideA { ownerPro === walletAddress ? '(you)' : '' }</Text>
                        <Text color='gray.800' fontWeight='bold' whiteSpace={'pre'}>
                            {forDeposit}
                        </Text>

                        <Text>SideB { ownerAgainst === walletAddress ? '(you)' : '' }</Text>
                        <Text color='gray.800' fontWeight='bold' whiteSpace={'pre'}>
                            {againstDeposit}
                        </Text>

                        <Text>Create date</Text>
                        <Text color='purple.400' fontWeight='bold' fontSize='md'>
                            {formatDt(moment.unix(otcEscrow.createTime.toNumber()))}
                        </Text>

                    </SimpleGrid>
                    <Flex>
                        { getButton() }
                    </Flex>
                    {category === OTCViewCategories.OPEN && walletAddress === ownerAgainst &&
                        <Flex  w='100%' textAlign='center'>
                            <Text size='sm' color='rose.400' w='100%' >
                                deposit {againstDeposit}
                            </Text>
                        </Flex>
                    }
                </Box>
            </Box>
        </Flex>
    );
}

export default OtcListEntry;
