import { Theme } from '@chakra-ui/react';

const styles: Theme['styles'] = {
    global: {
        '*': {
            _after: {
                boxSizing: 'border-box',
            },
            _before: {
                boxSizing: 'border-box',
            },
            boxSizing: 'border-box',
        },
        '.table-as-list': {
            'table': {
                borderCollapse: 'separate',
                borderSpacing: '0 10px',
                marginBottom: '34px'
            },
            'th': {
                fontWeight: 400,
                fontSize: '16px',
                textTransform: 'capitalize',
                lineHeight: '26px',
                color: '#656E91',
                borderBottom: 'none',
            },
            'td': {
                borderTop: `1px solid #D0D5DD`,
                borderBottom: `1px solid #D0D5DD`,
                background: '#fff',
                verticalAlign: 'middle',
                '&:first-of-type': {
                    borderTopLeftRadius: '10px',
                    borderBottomLeftRadius: '10px',
                    borderLeft: `1px solid #D0D5DD`,
                    paddingLeft: '33px',
                },
                '&:last-of-type': {
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                    borderRight: `1px solid #D0D5DD`,
                    paddingRight: '33px',
                }
            },
            'tr': {
                borderRadius: '15px',
            },
        },
        body: {
            bg: 'gray.100',
            MozOsxFontSmoothing: 'grayscale',
            position: 'relative',
            WebkitFontSmoothing: 'antialiased',
        },
    },
};

export default styles;
