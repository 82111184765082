import React, { useContext, useEffect, useMemo, useState } from 'react';
import { appChains, SwitchNetwork, Web3Context } from '../providers/Web3Provider';
import {
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Heading,
    Icon,
    IconButton,
    List,
    ListItem,
    Spacer,
    Spinner,
    Text,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import { ethers } from 'ethers';
import { erc20ABI, useContract, useNetwork, useProvider, useSwitchNetwork } from 'wagmi';
import generateNftSvg from '../helpers/generateNftSvg';
import { IDepositAsset, IEscrow, IFormattedAsset, ZERO_ADDY } from '../helpers/constants';
import getCurrencyDecimalsAndSymbol from '../helpers/getCurrencyDecimalsAndSymbol';
import { useParams } from 'react-router-dom';
import CenteredViewContainer from '../components/Containers/CenteredViewContainer';
import TransferModal from '../components/Modals/TransferModal';
import { IoMdSend } from 'react-icons/io';
import TagEscrowAbi from '../assets/abis/TagEscrow.json';
import TagNftAbi from '../assets/abis/TagNFT.json';
import getTagOwnerAddress from '../helpers/getTagOwnerAddress';
import UserDisplay from './UserDisplay';
import WalletConnectModal from '../components/Modals/WalletConnectModal';
import { uploadNftDataToIpfs } from '../helpers/uploadMetada';
import { Helmet } from 'react-helmet-async';

const DT_FORMAT = 'MMM D YY, hh:mm a'

const TagView: React.FC = () => {
    const toast = useToast();
    const { chain } = useNetwork();
    const {
        appConfig,
        walletAddress,
        isConnected,
        signer,
        contracts: { escrow }
    } = useContext(Web3Context);
    const { tagId, network } = useParams();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { isOpen: isWCOpen, onOpen: onWCOpen, onClose: onWCClose } = useDisclosure();
    const { switchNetworkAsync } = useSwitchNetwork();
    const [chainEscrow, setChainEscrow] = useState<IEscrow | undefined>(undefined);
    const [pageLoading, setPageLoading] = useState<boolean>(false);
    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const [forDeposit, setForDeposit] = useState<string>('');
    const [againstDeposit, setAgainstDeposit] = useState<string>('');
    const [ownerPro, setOwnerPro] = useState<string | undefined>(undefined);
    const [ownerAgainst, setOwnerAgainst] = useState<string | undefined>(undefined);
    const [tokenAddressA, setTokenAddressA] = useState<string>('');
    const [tokenAddressB, setTokenAddressB] = useState<string>('');

    const chainId = appChains.find(chn => chn.network === network)?.id ?? 1;
    const netWorkName = appChains.find(chn => chn.network === network)?.name;
    const genericProvider = useProvider({ chainId });

    const explorerBaseUrl = appChains.find(chn => chn.id === (chainId ?? chain?.id))?.blockExplorers?.default.url;
    const chainLogo = appConfig[chainId].logo;

    const genericEscrow = useContract({
        addressOrName: appConfig[chainId].escrowContractAddress,
        contractInterface: TagEscrowAbi.abi,
        signerOrProvider: genericProvider
    });
    const genericNft = useContract({
        addressOrName: appConfig[chainId].nftContractAddress,
        contractInterface: TagNftAbi,
        signerOrProvider: genericProvider
    });

    const currentChain = appChains.find(x => x.id === chainId);
    const onWrongNetwork = chain?.id !== chainId;

    const partyArbitrator = chainEscrow?.partyArbitrator;
    const started = chainEscrow?.started;
    const closed = chainEscrow?.closed;
    const escrowId = chainEscrow?.escrowId.toNumber();

    const isOpenWager = !started && !closed;
    const isStarted = started && !closed;
    const description = chainEscrow?.description;
    const arbitratorFeeBps = chainEscrow?.arbitratorFeeBps;
    const isZeroTag = chainEscrow?.partyA == ZERO_ADDY
        && chainEscrow?.partyB == ZERO_ADDY
        && chainEscrow?.partyArbitrator == ZERO_ADDY;

    useEffect(() => {
        loadEscrowDetails();
    }, [genericEscrow]);

    useEffect(() => {
        setParties();
    }, [chainEscrow, genericEscrow, genericNft]);

    const switchNetwork: SwitchNetwork = async (networkId: number) => {
        if (!switchNetworkAsync) {
            throw 'Something happened while trying to switch networks';
        }
        const network = networkId ? await switchNetworkAsync(networkId) : await switchNetworkAsync(80001);
        if (!network) {
            throw 'Something happened while trying to switch networks';
        }
    }

    const statusLabel = useMemo(() => {
        if (!chainEscrow) {
            return;
        }
        if (!chainEscrow.started && !chainEscrow.closed) {
            return { text: 'Pending Deposit', color: '#ffa', pinColor: '#b9b942' };
        }
        if (chainEscrow.started
            && !chainEscrow.closed
            && moment().isAfter(moment.unix(chainEscrow.determineTime.toNumber()))) {
            return { text: 'Waiting Arbitration', color: '#ffa', pinColor: '#b9b942' };
        }
        if (chainEscrow.started && !chainEscrow.closed) {
            return { text: 'Started', color: '#faf', pinColor: '#8a5c8a' };
        }
        if (chainEscrow.closed && chainEscrow.winner !== ZERO_ADDY) {
            return { text: 'Determined', color: '#aff', pinColor: '#b9b942' };
        }
        if (chainEscrow.closed) {
            return { text: 'Closed', color: '#faa', pinColor: '#815555' };
        }
    }, [chainEscrow])

    const isProSide = useMemo(() => walletAddress === ownerPro, [walletAddress, ownerPro]);

    const isParty = useMemo(() => walletAddress === ownerPro
        || walletAddress === ownerAgainst
        || walletAddress === partyArbitrator, [walletAddress, ownerPro, ownerAgainst, partyArbitrator]);

    const isArbitrator = useMemo(() => walletAddress === partyArbitrator,
        [walletAddress, partyArbitrator]);

    const determineUnix = chainEscrow ? moment.unix(chainEscrow.determineTime.toNumber()) : null;
    const depositPending1Day = chainEscrow
        ? moment().isAfter(moment.unix(chainEscrow.createTime.toNumber()).add(24, 'h'))
        : null;
    const undeterminedPast3Days = determineUnix ? moment().isAfter(determineUnix.add(72, 'h')) : null;
    const canDeposit =  useMemo(() => isOpenWager && isParty && isConnected && !isProSide && !isArbitrator,
        [isOpenWager, isParty, isConnected, isProSide, isArbitrator]);
    const canWithdraw =  useMemo(() => isOpenWager && isParty && isConnected && depositPending1Day && isProSide
            && !isArbitrator, [isOpenWager, isParty, isConnected, depositPending1Day, isProSide, isArbitrator]);
    const canReclaim =  useMemo(() =>  isConnected && isParty && undeterminedPast3Days && isStarted
            && !isArbitrator, [undeterminedPast3Days, isParty, isConnected, isStarted, isArbitrator]);
    const canAcceptChallenge =  useMemo(() =>  isOpenWager && isConnected && !isArbitrator
            && !isParty && chainEscrow?.partyB === ethers.constants.AddressZero,
    [isOpenWager, isParty, isConnected, isArbitrator, chainEscrow]);

    const setParties = async () => {
        if (!genericEscrow || !genericNft || !chainEscrow) {
            return;
        }
        setOwnerPro(await getTagOwnerAddress(chainEscrow, genericNft));
        setOwnerAgainst(await getTagOwnerAddress(chainEscrow, genericNft, false));
    }

    const depositToEscrow = async () => {
        if (!signer || !escrow || !chainEscrow) {
            return;
        }
        setBtnLoading(true);
        const currencyAddress = chainEscrow.pendingAssetB.currency;
        const currencyAmount = chainEscrow.pendingAssetB.amount;
        const payingWithGas = currencyAddress === ZERO_ADDY;

        const token = new ethers.Contract(currencyAddress, erc20ABI, signer);
        if (!payingWithGas) {
            const allowance = await token.allowance(walletAddress, escrow.address);
            if (allowance < currencyAmount) {
                await (
                    await token.approve(escrow.address, currencyAmount)
                ).wait();
            }
        }
        try {
            const nftAIpfsUrl = await generateNft(true);
            const nftBIpfsUrl = await generateNft(false);
            const paymentParams = { value: !payingWithGas ? 0 : currencyAmount };
            const tx = await escrow.depositFunds(escrowId, nftAIpfsUrl, nftBIpfsUrl, { ...paymentParams });
            await tx.wait();
            window.location.reload();
        } catch (e: any) {
            console.error('depositToEscrow', e)
            toast({
                title: e?.reason ?? e?.data?.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setBtnLoading(false);
        }

    };

    const withdrawFunds = async (escrowContract: ethers.Contract) => {
        if (!signer || !escrowContract) {
            return;
        }

        setBtnLoading(true);

        try{
            const tx = await escrowContract.withdrawFunds(escrowId);
            await tx.wait();
            window.location.reload();
            setBtnLoading(false);
        }  catch (e: any) {
            console.error(e)
            toast({
                title: e?.reason ?? e?.data?.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setBtnLoading(false);
        }
    };

    const reclaimFunds = async (escrowContract: ethers.Contract) => {
        if (!signer || !escrowContract) {
            return;
        }
        try {
            setBtnLoading(true);
            const tx = await escrowContract.reclaimFunds(escrowId);
            await tx.wait();
            setBtnLoading(false);
            window.location.reload();
        } catch (e: any) {
            console.error(e)
            toast({
                title: e?.reason ?? e?.data?.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setBtnLoading(false);
        }
    };

    const generateNft = async (forPartyA: boolean): Promise<string> => {
        if (!signer || !escrow || !chainEscrow) {
            throw new Error('Params not set yet');
        }
        const assetsA = await escrow.escrowAssetsA(escrowId);
        const assetsB = chainEscrow.pendingAssetB;
        const svg = generateNftSvg(
            chainEscrow,
            await getFormattedToken(assetsA),
            await getFormattedToken(assetsB),
            forPartyA
        );
        const blob = new Blob([svg], { type: 'image/svg+xml' });
        return await uploadNftDataToIpfs(
            chainEscrow,
            forPartyA,
            assetsA.currency,
            assetsB.currency,
            blob
        );
    }

    const loadEscrowDetails = async () => {
        if (!genericEscrow || !currentChain) {
            return;
        }
        setPageLoading(true);
        try {
            const fetchedEscrow = await genericEscrow.escrows(Number(tagId));
            const escrowId = fetchedEscrow.escrowId;
            const assetsA = await genericEscrow.escrowAssetsA(escrowId);
            const assetsB = fetchedEscrow.pendingAssetB;
            const currencyA = new ethers.Contract(assetsA.currency, erc20ABI, genericProvider);
            const currencyB = new ethers.Contract(assetsB.currency, erc20ABI, genericProvider);
            const { decimals: decimalsA, symbol: symbolA
            } = await getCurrencyDecimalsAndSymbol(currencyA, currentChain);
            const { decimals: decimalsB, symbol: symbolB
            } = await getCurrencyDecimalsAndSymbol(currencyB, currentChain);
            const amountA = ethers.utils.formatUnits(`${assetsA.amount}`, decimalsA);
            const amountB = ethers.utils.formatUnits(`${assetsB.amount}`, decimalsB);
            setForDeposit(amountA + ' ' + symbolA);
            setAgainstDeposit(amountB + ' ' + symbolB);
            setTokenAddressA(assetsA.currency);
            setTokenAddressB(assetsB.currency);
            setChainEscrow(fetchedEscrow);
            setPageLoading(false);
        } catch (e) {
            console.error('loadEscrowDetails', e);
        }
    }

    const getFormattedToken = async (asset: IDepositAsset): Promise<IFormattedAsset> => {
        if (!genericEscrow || !chain) {
            throw new Error('Signer not connected');
        }
        const tokenContract = new ethers.Contract(asset.currency, erc20ABI, signer ?? genericProvider);
        const { decimals, symbol } = await getCurrencyDecimalsAndSymbol(tokenContract, chain);
        return {
            symbol,
            address: tokenContract.address,
            decimals,
            amount: asset.amount
        };
    }

    const wrapLoading = async (method: () => void): Promise<void> => {
        setBtnLoading(true);
        try {
            await method();
        } catch (e: any) {
            toast({
                title: e?.reason,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setBtnLoading(false);
        }
    }

    useEffect(() => {
        if (!chainEscrow) {
            return;
        }
        const actOnNewNetwork = isConnected && (canDeposit || canWithdraw || canReclaim || canAcceptChallenge)
            && onWrongNetwork;
        if (actOnNewNetwork) {
            switchNetwork(chainId)
        }
        return;
    }, [chainEscrow, chain, isConnected, chainId, currentChain])

    const getButton = () => {
        if (!chainEscrow || !escrow) {
            return;
        }
        const determineUnix = moment.unix(chainEscrow.determineTime.toNumber());
        const depositPending1Day = moment().isAfter(
            moment.unix(chainEscrow.createTime.toNumber()).add(24, 'h')
        );
        const undeterminedPast3Days = moment().isAfter(determineUnix.add(72, 'h'));
        const btnProps = {
            borderRadius: 5,
            bg: 'button.action',
            color: 'white',
            w: 'full',
            _hover: { bg: 'tagmi.logo' },
            _focus: { bg: 'tagmi.logo' },
        };
        const canDeposit = isOpenWager && isParty && isConnected && !isProSide && !isArbitrator;
        const canWithdraw = isOpenWager && isParty && isConnected && depositPending1Day && isProSide&& !isArbitrator;
        const canReclaim = isConnected && isParty && undeterminedPast3Days && isStarted && !isArbitrator;
        const canAcceptChallenge = isOpenWager && isConnected && !isArbitrator && !isParty
            && chainEscrow.partyB === ethers.constants.AddressZero
        const actOnNewNetwork = isConnected && (canDeposit || canWithdraw || canReclaim || canAcceptChallenge)
            && onWrongNetwork;

        return (
            <>
                {canDeposit && !onWrongNetwork && (
                    <Button
                        {...btnProps}
                        isLoading={btnLoading}
                        onClick={() => depositToEscrow()}>
                        Approve and Deposit
                    </Button>
                )}
                {canAcceptChallenge && !onWrongNetwork && (
                    <Button
                        {...btnProps}
                        isLoading={btnLoading}
                        onClick={() => depositToEscrow()}>
                        Accept Challenge
                    </Button>
                )}
                {canWithdraw && !onWrongNetwork && (
                    <Button
                        {...btnProps}
                        isLoading={btnLoading}
                        onClick={() => wrapLoading(() => withdrawFunds(escrow))}>
                        Withdraw funds
                    </Button>
                )}
                {canReclaim && (
                    <Button
                        {...btnProps}
                        isLoading={btnLoading}
                        isDisabled={onWrongNetwork}
                        onClick={() => wrapLoading(() => reclaimFunds(escrow))}>
                        Reclaim funds
                    </Button>
                )}
                {actOnNewNetwork && (
                    <Button
                        borderRadius={0}
                        px={6}
                        py={8}
                        shadow='md'
                        bgColor='gray.50'
                        isLoading={btnLoading}
                        _hover={{ bg: 'tagmi.logo' }}
                        onClick={() => switchNetwork(chainId)}
                        leftIcon={<Icon as={chainLogo} h='60px' w='60px' />}>
                        {netWorkName ? `Switch to ${netWorkName}` : `Switch to TAG's network`}
                    </Button>
                )}
            </>
        )
    }

    return (
        <CenteredViewContainer>
            <Helmet>
                <meta name='twitter:title' content={`TAG#${tagId}${network ? ` (${network})` : ''}`}/>
                <meta property='og:title' content={`TAG#${tagId}${network ? ` (${network})` : ''}`} />

                <meta name='twitter:description' content={`${description}\n ${forDeposit} vs ${againstDeposit}`}/>
                <meta name='og:description' content={`${description}\n ${forDeposit} vs ${againstDeposit}`}/>
                <meta
                    name='twitter:image'
                    content={'%PUBLIC_URL%/logo512.png'} />
                <meta
                    property='og:image'
                    content={'%PUBLIC_URL%/logo512.png'} />
            </Helmet>
            {pageLoading &&
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    alignItems='center'
                    color='blue.500'
                    size='xl'/>
            }

            {chainEscrow
                && !pageLoading
                && forDeposit
                && againstDeposit
                && !isZeroTag
                && partyArbitrator
                && ownerPro
                && ownerAgainst
                && statusLabel && (
                <Flex direction='column' w='100%' h='100vh'>
                    <Flex w='100%' mb={55}>
                        <Flex m='0 auto'>
                            <Icon as={chainLogo} h='60px' w='60px' mr='15px' />
                            <Heading
                                lineHeight={1.1}
                                fontWeight={600}
                                fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}>
                                    TAG#{ escrowId }
                            </Heading>
                            <Flex
                                ml='25px'
                                alignSelf='center'
                                bg={statusLabel.color}
                                px={3}
                                p={2}
                                borderRadius={10}
                                userSelect='none'>
                                <Center>
                                    <Box
                                        w='10px'
                                        h='10px'
                                        mr={2}
                                        borderRadius='50%'
                                        padding='4px'
                                        bg={statusLabel.pinColor} />
                                </Center>
                                <Text w='100%' textAlign='center'>
                                    { statusLabel.text }
                                </Text>
                            </Flex>

                        </Flex>
                        {isStarted && signer && isParty && !isArbitrator && (
                            <IconButton
                                aria-label='Transfer claim'
                                colorScheme='teal'
                                fontSize='20px'
                                onClick={onOpen}
                                isLoading={btnLoading}
                                isDisabled={onWrongNetwork}
                                variant='outline'
                                icon={<IoMdSend />}/>
                        )}
                    </Flex>
                    <Flex>
                        <Flex direction='column' w='49.5%' textAlign='right'>
                            <Text><UserDisplay address={ownerPro}/></Text>
                            <Text  color='green.500'>
                                    FOR - <Box
                                    as='span'
                                    cursor='pointer'
                                    fontWeight='bold'
                                    onClick={() => window.open(
                                        `${explorerBaseUrl}/token/${tokenAddressA}`,
                                        '_blank',
                                        'noreferrer')
                                    }
                                    _hover={{
                                        textDecoration: 'underline'
                                    }}>
                                    {forDeposit}
                                </Box>
                            </Text>
                        </Flex>
                        <Flex direction='column' w='20%'>
                            <br/>
                            <Text textAlign='center' fontWeight='bold' fontSize='lg'>HAS BET</Text>
                        </Flex>
                        <Flex direction='column' w='49.5%'>
                            <Text><UserDisplay address={ownerAgainst}/></Text>
                            <Text  color='red.500'>
                                <Box
                                    as='span'
                                    cursor='pointer'
                                    fontWeight='bold'
                                    onClick={() => window.open(
                                        `${explorerBaseUrl}/token/${tokenAddressB}`,
                                        '_blank',
                                        'noreferrer')
                                    }
                                    _hover={{
                                        textDecoration: 'underline'
                                    }}>
                                    {againstDeposit}
                                </Box> - AGAINST</Text>
                        </Flex>
                    </Flex>
                    <Flex mt={8} textAlign='center' w='100%' fontWeight='bold'>
                        <Spacer />
                        <Text fontSize='lg'>
                                THAT
                        </Text>
                        <Spacer />
                    </Flex>
                    <Flex mt={1}>
                        <Text textAlign='center' w='100%' fontSize='2xl'>
                            { description }
                        </Text>
                    </Flex>

                    <Divider mt={5} colorScheme='telegram' size='5px'/>

                    <Flex direction='column' w='100%' alignItems='center' mt={10}>
                        <List spacing={2} fontSize='sm'>
                            <ListItem>
                                <Text as={'span'} fontWeight={'bold'}>
                                        Resolution date:
                                </Text>{' '}
                                {moment.unix(chainEscrow.determineTime.toNumber()).format(DT_FORMAT)}
                            </ListItem>
                            <ListItem>
                                <Text as={'span'} fontWeight={'bold'}>
                                        Arbitrator:
                                </Text>{' '}
                                <UserDisplay address={partyArbitrator} />
                            </ListItem>
                            <ListItem>
                                <Text as={'span'} fontWeight={'bold'}>
                                        Arbitrator fee:
                                </Text>{' '}
                                {arbitratorFeeBps ? arbitratorFeeBps.toNumber()/100 : 0}%
                            </ListItem>
                            <ListItem>
                                <Text as={'span'} fontWeight={'bold'}>
                                        Winner:
                                </Text>{' '}
                                {chainEscrow.winner !== ZERO_ADDY
                                    ? chainEscrow.winner === walletAddress
                                        ? 'You!'
                                        : chainEscrow.winner
                                    : 'No winner yet'
                                }
                            </ListItem>
                        </List>
                    </Flex>

                    <Box w='100%' textAlign='center' mt={50}>
                        {!isConnected && (
                            <Button
                                borderRadius='10px'
                                colorScheme='blue'
                                size='3xl'
                                p='16px'
                                onClick={onWCOpen}>
                                    Connect Wallet to Accept Challenge!
                            </Button>
                        )}
                        { isConnected && <>{ getButton() }</> }
                    </Box>

                </Flex>
            )
            }
            {!pageLoading && (!chainEscrow || isZeroTag) && <Text fontSize='xl'>No tag found</Text> }

            {chainEscrow &&
                <TransferModal isOpen={isOpen} onClose={onClose} chainEscrow={chainEscrow} />
            }
            <WalletConnectModal isOpen={isWCOpen} onClose={onWCClose} />
        </CenteredViewContainer>
    );
}

export default TagView;
