import React, { useContext, useEffect, useState } from 'react';
import { IEscrow } from '../helpers/constants';
import ViewContainer from '../components/Containers/ViewContainer';
import { Web3Context } from '../providers/Web3Provider';
import { Center, InputGroup, SimpleGrid, Text } from '@chakra-ui/react';
import OpenEscrow from '../components/OpenEscrow';
import { useDebounce } from '../hooks/useDebounce';
import { SearchIcon } from '@chakra-ui/icons';
import Input from '../components/Form/Input';

const MarketPlace: React.FC = () => {
    const { isConnected, contracts: { escrow } } = useContext(Web3Context);
    const [escrows, setEscrows] = useState<IEscrow[]>([]);
    const [searchResult, setSearchResult] = useState<IEscrow[]>(escrows);
    const [search, setSearch] = useState<string>('');
    const debouncedSearchTerm = useDebounce(search, 300);

    useEffect(() => {
        loadEscrows();
    }, [escrow]);

    const loadEscrows = async () => {
        if (!escrow) {
            return;
        }
        const openEndedIds = await escrow.getOpenEndedEscrows();
        const scrsPromises: Promise<any>[] = [];
        openEndedIds.map((id: number) => scrsPromises.push(escrow.escrows(id)))
        const walletEscrows = await Promise.all(scrsPromises);
        setEscrows(walletEscrows);
    }

    useEffect(() => {
        let results: IEscrow[] = escrows;
        if (debouncedSearchTerm) {
            results = escrows.filter(escr => escr
                .description
                .toLowerCase()
                .includes(debouncedSearchTerm.toLowerCase())
            );
        }
        setSearchResult(results);
    }, [debouncedSearchTerm, escrows]);

    return (
        <ViewContainer>
            <InputGroup w={{ base: '100%', md: '50%', lg: '300px' }}>
                <Input
                    background='white'
                    borderColor='borderColor'
                    onChange={e => setSearch(e.target.value)}
                    rightElement={{
                        mr: '20px',
                        height: '100%',
                        width: 'auto',
                        children: <SearchIcon stroke='gray' />
                    }}
                    value={search}
                    placeholder='Search' />
            </InputGroup>
            { isConnected && (
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacingX='20' spacingY='5' marginY={'5'}>
                    { searchResult.map(esc => (
                        <OpenEscrow chainEscrow={esc} key={esc.escrowId.toNumber()} />
                    ))}
                    {searchResult.length === 0 &&
                        <Center fontSize='lg' w='100%'>
                            <Text>
                                Nothing here yet. Change network or adjust search parameters.
                            </Text>
                        </Center>
                    }
                </SimpleGrid>
            )}

            {!isConnected &&
                <Center fontSize='lg'>
                    Connect your wallet to see Open bets
                </Center>
            }
        </ViewContainer>
    );
}

export default MarketPlace;
