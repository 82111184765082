import { Colors } from '@chakra-ui/react';

const colors: Colors = {
    borderColor: '#D0D5DD',
    error: {
        900: '#7A271A',
        800: '#912018',
        700: '#B42318',
        600: '#D92D20',
        500: '#F04438',
        400: '#F97066',
        300: '#FDA29B',
        200: '#FECDCA',
        100: '#FEE4E2',
        50: '#FEF3F2',
    },
    gray: {
        900: '#101828',
        800: '#1D2939',
        700: '#344054',
        600: '#475467',
        500: '#667085',
        400: '#98A2B3',
        300: '#D0D5DD',
        200: '#EAECF0',
        100: '#F2F4F7',
        50: '#F9FAFB',
        main: '#2D2D2D',
    },
    primary: '#1F2B59',
    rose: {
        900: '#89123E',
        800: '#A11043',
        700: '#C01048',
        600: '#E31B54',
        500: '#F63D68',
        400: '#FD6F8E',
        300: '#FEA3B4',
        200: '#FECDD6',
        100: '#FFE4E8',
        50: '#FFF1F3',
    },
    red: {
        main: '#E54D42',
    },
    green: {
        main: '#02CC88',
        100: '#D1FADF',
        200: '#A6F4C5',
        700: '#087443'
    },
    button: {
        action: '#25273D'
    },
    secondary: '#656E91',
    link: '#2662D7',
    white: '#fff',
    stefanos: {
        0: '#DFF6F8',
        1: '#dfe6f8'
    },
    tagmi: {
        logo: '#00a2e9',
        light: '#a5bdcc',
        dark: '#0176A6FF'
    }
};

export default colors;
