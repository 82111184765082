import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Tag from './views/Tag';
import Connect from './views/Connect';
import React from 'react';
import { Footer } from './components/Footer';
import NotFound from './views/NotFound';
import PasswordModal from './components/Modals/PasswordModal';
import Otc from './views/Otc';
import { AppRoute } from './helpers/constants';
import TagView from './views/TagView';
import Missions from './views/Missions';
import MarketPlace from './views/MarketPlace';

const AppRoutes = () => (
    <BrowserRouter>
        <Routes>
            <Route path={AppRoute.TAG} element={<Tag />} />
            <Route path={`${AppRoute.TAG}/tag/:tagId/:network?`} element={<TagView/>} />
            <Route path={AppRoute.SWAP} element={<Otc />} />
            <Route path={AppRoute.MARKET} element={<MarketPlace />} />
            <Route path={AppRoute.CONNECT} element={<Connect />} />
            <Route path={AppRoute.MISSIONS} element={<Missions />} />
            <Route path={'*'} element={<NotFound />}/>
        </Routes>
        <Footer/>
        <PasswordModal />
    </BrowserRouter>
);

export default AppRoutes;
