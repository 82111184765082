import { useContractRead } from 'wagmi';
import { APP_NETWORK_CONFIG, APP_NETWORK_CONFIG_v1, APP_NETWORK_CONFIG_v2 } from '../../helpers/appConfig';
import TagEscrowAbi from '../../assets/abis/TagEscrow.json';
import TagEscroOtcAbi from '../../assets/abis/TagEscrowOtc.json';
import React, { useMemo } from 'react';
import { Flex, Modal, ModalBody, ModalContent, ModalOverlay, SimpleGrid, Spinner, Text } from '@chakra-ui/react';
import { BigNumber } from 'ethers';

interface IStatisticsMProps {
    isOpen: boolean;
    onClose: () => void;
}

const StatisticsModal: React.FC<IStatisticsMProps> = ({ isOpen, onClose }) => {
    const { data: ethV1Data, isLoading: loadingE1 } = useContractRead({
        addressOrName: APP_NETWORK_CONFIG_v1[1].escrowContractAddress,
        chainId: 1,
        contractInterface: TagEscrowAbi.abi,
        functionName: 'nextEscrowId'
    });
    const { data: arbV1Data, isLoading: loadingA1 } = useContractRead({
        addressOrName: APP_NETWORK_CONFIG_v1[42161].escrowContractAddress,
        chainId: 42161,
        contractInterface: TagEscrowAbi.abi,
        functionName: 'nextEscrowId'
    });

    const { data: ethV2Data, isLoading: loadingE2 } = useContractRead({
        addressOrName: APP_NETWORK_CONFIG_v2[1].escrowContractAddress,
        chainId: 1,
        contractInterface: TagEscrowAbi.abi,
        functionName: 'nextEscrowId'
    });
    const { data: arbV2Data, isLoading: loadingA2 } = useContractRead({
        addressOrName: APP_NETWORK_CONFIG_v2[42161].escrowContractAddress,
        chainId: 42161,
        contractInterface: TagEscrowAbi.abi,
        functionName: 'nextEscrowId'
    });

    const { data: ethV3Data, isLoading: loadingE3 } = useContractRead({
        addressOrName: APP_NETWORK_CONFIG[1].escrowContractAddress,
        chainId: 1,
        contractInterface: TagEscrowAbi.abi,
        functionName: 'nextEscrowId'
    });
    const { data: arbV3Data, isLoading: loadingA3 } = useContractRead({
        addressOrName: APP_NETWORK_CONFIG[42161].escrowContractAddress,
        chainId: 42161,
        contractInterface: TagEscrowAbi.abi,
        functionName: 'nextEscrowId'
    });

    const { data: otcData, isLoading: otcLoading } = useContractRead({
        addressOrName: APP_NETWORK_CONFIG[1].escrowOtcAddress,
        chainId: 1,
        contractInterface: TagEscroOtcAbi.abi,
        functionName: 'nextEscrowId'
    });
    const { data: otcDataArb, isLoading: otcLoadngArb } = useContractRead({
        addressOrName: APP_NETWORK_CONFIG[42161].escrowOtcAddress,
        chainId: 42161,
        contractInterface: TagEscroOtcAbi.abi,
        functionName: 'nextEscrowId'
    });

    const { data: openTagData } = useContractRead({
        addressOrName: APP_NETWORK_CONFIG[1].escrowContractAddress,
        chainId: 1,
        contractInterface: TagEscrowAbi.abi,
        functionName: 'getOpenEndedEscrows'
    });

    const { data: openTagDataArb } = useContractRead({
        addressOrName: APP_NETWORK_CONFIG[42161].escrowContractAddress,
        chainId: 42161,
        contractInterface: TagEscrowAbi.abi,
        functionName: 'getOpenEndedEscrows'
    });

    const isLoading = useMemo(() => loadingA1
            || loadingE1
            || loadingA2
            || loadingE3
            || loadingA2
            || loadingE3
            || loadingE2
            || otcLoadngArb
            || otcLoading,
    [loadingA1, loadingE1, loadingA2,  loadingE2, loadingA3, loadingE3, otcLoadngArb, otcLoading]);

    const gridItem = (label: string, value: string | undefined) => (
        <Flex direction='column' textAlign='center' border='0.1px solid' borderColor='gray.400' py={8} px={0}>
            <Text size='lg' color='gray.500'>
                { label }
            </Text>
            <Text size='xl' color='gray.800' fontWeight='bold'>
                { value }
            </Text>
        </Flex>
    )

    const getCount = (...other: any): number =>  {
        let c = BigNumber.from('0');
        for (const r of other) {
            if (r) {
                c = c.add(r);
            }
        }
        return c.toNumber();
    };

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose} size='xl' closeOnEsc>
            <ModalOverlay bg='blackAlpha.500'/>
            <ModalContent>
                <ModalBody p={0}>
                    { !isLoading && (
                        <SimpleGrid columns={2} w='100%' p={0}>
                            { gridItem('TAGs',
                                getCount(ethV3Data, ethV1Data, arbV1Data, arbV3Data, arbV2Data, ethV2Data).toString())
                            }
                            { gridItem('Open TAGs', (openTagData && openTagDataArb
                                ? openTagData?.length + openTagDataArb?.length
                                : 0).toString()
                            ) }

                            { gridItem('Most used token', 'USDC') }
                            { gridItem('TAG Volume', '$3.65k') }

                            { gridItem('OTC deals', getCount(otcData, otcDataArb).toString()) }
                            { gridItem('OTC Volume', '$0') }
                        </SimpleGrid>
                    ) }
                    { isLoading &&
                        <Flex direction='column'>
                            <Text mb={5}>Collecting stats from the chains</Text>
                            <Spinner size='md' m='0 auto'/>
                        </Flex>
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default StatisticsModal;
