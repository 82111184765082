import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Image, Spacer,
    Spinner,
    Text,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ethers } from 'ethers';
import { erc20ABI, useNetwork } from 'wagmi';
import { Web3Context } from '../../providers/Web3Provider';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { z } from 'zod';
import Input from '../../components/Form/Input';
import { useLocation, useNavigate } from 'react-router-dom';
import CurrencyModal from '../../components/Modals/CurrencyModal';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { AppRoute, ZERO_ADDY } from '../../helpers/constants';
import getCurrencyDecimalsAndSymbol from '../../helpers/getCurrencyDecimalsAndSymbol';
import TweetModal from '../../components/Modals/TweetModal';
import ReactGA from 'react-ga4';
import WalletConnectModal from '../../components/Modals/WalletConnectModal';
import confetti from '../../helpers/confetti';

const schema = z.object({
    chainId: z.number().nullish(),
    partyB: z
        .string({ required_error: 'You need to set a counterparty (address)' })
        .refine(value => ethers.utils.isAddress(value),
            { message: 'This does not look like a valid Wallet address!'}
        ),
    currencyAddress:  z
        .string({ required_error: 'You need to set a currency to spend!' })
        .refine(value => ethers.utils.isAddress(value),
            { message: 'This does not look like a valid token' }),
    currencyAddressB:  z
        .string({ required_error: 'You need to set a currency to spend!' })
        .refine(value => ethers.utils.isAddress(value),
            { message: 'This does not look like a valid token' }),
    currencyAmount: z.coerce.number().min(0.01).step(0.01),
    currencyAmountB: z.coerce.number().min(0.01).step(0.01)
});

type Schema = z.infer<typeof schema>;

export interface ISelectToken {
    symbol: string;
    name: string;
    address: string;
    decimals: number;
    logoURI: string;
}

const CreateEscrowOtc: React.FC = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const { search } = useLocation();
    const { chain } = useNetwork();
    const {
        appConfig, walletAddress, signer, isConnected, wrongChain, contracts: { escrowOtc }
    } = useContext(Web3Context);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isWCOpen, onOpen: onWCOpen, onClose: onWCClose } = useDisclosure();
    const { isOpen: isOpenB, onOpen: onOpenB, onClose: onCloseB } = useDisclosure();
    const { isOpen: isOpenT, onOpen: onOpenT, onClose: onCloseT } = useDisclosure();

    const form = useForm<Schema>({
        resolver: zodResolver(schema),
    });
    const { control, getValues, watch, handleSubmit, setValue } = form;

    const [tokenContract, setTokenContract] = useState<ethers.Contract | null>(null);
    const [tokenContractB, setTokenContractB] = useState<ethers.Contract | null>(null);
    const [selectedTokenA, setSelectedTokenA] = useState<ISelectToken | null>(null);
    const [selectedTokenB, setSelectedTokenB] = useState<ISelectToken | null>(null);
    const [spendApproved, setSpendApproved] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [tweetTagId, setTweetTagId] = useState<number | null>(null);
    const [tweetAmount, setTweetAmount] = useState<string | null>(null);

    const currencyAddress = watch('currencyAddress');
    const currencyAmount = watch('currencyAmount');
    const currencyAddressB = watch('currencyAddressB');
    const currencyAmountB = watch('currencyAmountB');

    useEffect(() => {
        setValue('chainId', chain?.id);
    } ,[chain]);

    const loadTokenAndApprovals = useCallback(async () => {
        if (!escrowOtc || !signer) {
            return;
        }
        try {
            if (!ethers.utils.isAddress(currencyAddress)) {
                return;
            }
            setButtonLoading(true);
            let approvedSpend: boolean;
            const tokenC = new ethers.Contract(currencyAddress, erc20ABI, signer);
            if (currencyAddress === ZERO_ADDY) {
                approvedSpend = true;
            } else {
                const decimals = await tokenC.decimals();
                const allowance = await tokenC.allowance(walletAddress, escrowOtc.address);
                const allowanceNum = Number(ethers.utils.formatUnits(`${allowance}`, decimals));
                approvedSpend = allowanceNum >= Number(currencyAmount);
            }

            setTokenContract(tokenC);
            setSpendApproved(approvedSpend);
        } catch (e) {
            setSelectedTokenA(null);
            setSpendApproved(false);
        } finally {
            setButtonLoading(false);
        }
    }, [escrowOtc, currencyAddress, currencyAmount, signer, walletAddress]);

    useEffect(() => {
        loadTokenAndApprovals();
    }, [currencyAddress, currencyAmount, loadTokenAndApprovals]);

    useEffect(() => {
        loadTokenB();
    }, [currencyAddressB, currencyAmountB]);

    const onSelectCurrencyA = (currency: ISelectToken): void => {
        if (currency.address === selectedTokenA?.address) {
            return;
        }
        onClose();
        setSelectedTokenA(currency);
        setValue('currencyAddress', currency.address);
        if (!currencyAddressB) {
            setSelectedTokenB(currency);
            setValue('currencyAddressB', currency.address);
        }
    };

    const onSelectCurrencyB = (currency: ISelectToken) => {
        if (currency.address === selectedTokenB?.address) {
            return;
        }
        onCloseB();
        setSelectedTokenB(currency);
        setValue('currencyAddressB', currency.address);
    }

    const validateAmount = (value: number, isCurrencyA: boolean) => {
        if (!chain) {
            return false;
        }
        const key = isCurrencyA ? 'currencyAmount' : 'currencyAmountB';
        const currency = isCurrencyA ? currencyAddress : currencyAddressB;
        const defaultCurrency = appConfig[chain.id].defaultCoins.find((coin: any) => coin.address === currency);
        if (defaultCurrency) {
            const minV = defaultCurrency.min;
            if (Number(value) < minV) {
                form.setError(key, { message: `The minimum for this currency is ${minV}` });
                return false;
            }
        }
        return true;
    }

    const loadTokenB = useCallback(async () => {
        if (!escrowOtc || !signer) {
            return;
        }
        if (!ethers.utils.isAddress(currencyAddressB)) {
            return;
        }
        const tokenC = new ethers.Contract(currencyAddressB, erc20ABI, signer);
        setTokenContractB(tokenC);
    }, [escrowOtc, currencyAddressB, currencyAmountB, signer])

    const createEscrow = useCallback(async () => {
        if (!signer || !escrowOtc || !spendApproved || !tokenContract || !tokenContractB || !chain) {
            return;
        }
        try {
            setButtonLoading(true);
            const formData = getValues();
            const amount1Valid = validateAmount(formData.currencyAmount, true);
            const amount2Valid = validateAmount(formData.currencyAmountB, false);
            if (!amount1Valid || !amount2Valid) {
                return;
            }
            const payingWithGas = formData.currencyAddress === ZERO_ADDY;
            const { decimals } = await getCurrencyDecimalsAndSymbol(tokenContract, chain);
            const { decimals:  decimalsB } = await getCurrencyDecimalsAndSymbol(tokenContractB, chain);
            const paymentParams = {
                value: !payingWithGas
                    ? 0
                    : ethers.utils.parseEther(`${formData.currencyAmount}`)
            };
            const amount = ethers.utils.parseUnits(`${formData.currencyAmount}`, decimals);
            const amountB = ethers.utils.parseUnits(`${formData.currencyAmountB}`, decimalsB);
            const tx = await escrowOtc.createEscrow(
                formData.partyB,
                formData.currencyAddress,
                amount,
                formData.currencyAddressB,
                amountB,
                { ...paymentParams }
            );
            await tx.wait();
            const tagId = await escrowOtc.nextEscrowId(); // WARNING: This may be wrong when we got a high voluime
            toast({
                title: 'Swap Created!',
                description: `Created OTC Swap for ${formData.currencyAmount} ${selectedTokenA?.symbol}`,
                status: 'success',
                duration: 9000,
                isClosable: false,
            });
            ReactGA.event({
                category: 'CREATE',
                action: 'CreatedOTC',
                value: Number(formData.currencyAmount),
            });
            onOpenTweet(formData.currencyAmount.toString(), selectedTokenA?.symbol, tagId.toNumber() - 1);
            confetti();
        } catch (e: any) {
            toast({
                title: e?.reason,
                status: 'error',
                duration: 9000,
                isClosable: false,
            });
        } finally {
            setButtonLoading(false);
        }
    }, [
        chain, escrowOtc, signer,
        spendApproved, walletAddress,
        tokenContract, tokenContractB,
        currencyAmount, currencyAmountB
    ]);

    const approveSpend = useCallback(async () => {
        if (!tokenContract || !walletAddress || !escrowOtc) {
            return;
        }

        try {
            const formData = getValues();
            const amount1Valid = validateAmount(formData.currencyAmount, true);
            const amount2Valid = validateAmount(formData.currencyAmountB, false);
            if (!amount1Valid || !amount2Valid) {
                return;
            }
            const decimals = await tokenContract.decimals();
            const approveGwei = ethers.utils.parseUnits(`${currencyAmount}`, decimals);
            setButtonLoading(true);
            await (
                await tokenContract.approve(escrowOtc.address, `${approveGwei}`)
            ).wait();
            setButtonLoading(false);
            setSpendApproved(true);
            toast({
                title: 'Approved spend. Now you can create a Swap',
                status: 'success',
                duration: 7000,
                isClosable: false,
            });
            ReactGA.event({
                category: 'CREATE',
                action: 'ApprovedSpend',
            });
        } catch (e) {
            console.log(e)
            setButtonLoading(false);
        }
    }, [escrowOtc, tokenContract, currencyAmount, currencyAmountB, selectedTokenB, selectedTokenB, walletAddress]);

    const onOpenTweet = (amount: string, currencyName: string | undefined, tagId: number) => {
        setTweetAmount(amount);
        setTweetTagId(tagId);
        onOpenT();
    }

    const onCloseTweet = () => {
        setTweetAmount(null);
        setTweetTagId(null);
        onCloseT();
        navigate(AppRoute.SWAP + search, { replace: true });
    }

    const needsToApprove = useMemo(() => !spendApproved && tokenContract && currencyAmount > 0,
        [spendApproved, tokenContract, currencyAmount]);

    return (
        <Box>
            <Flex
                alignItems='center'
                background='white'
                borderRadius={8}
                border='1px solid gray.300'
                direction='column'
                gap='1'
                m='auto'
                maxW={450}
                minW={350}
                p='16px'>
                <FormProvider {...form}>
                    <form
                        autoComplete='off'
                        onSubmit={handleSubmit(spendApproved ? createEscrow : approveSpend)}>
                        <Flex direction='column' gap='2' w='100%'>
                            <Flex direction='row'>
                                <Text
                                    color='gray.600'
                                    fontSize='16px'
                                    fontWeight='bold'
                                    mb='10px'
                                    textAlign='left'>
                                    OTC Swap
                                </Text>
                                <Spacer />
                                <Text fontSize='13px' textAlign='right'>
                                    Platform Fee: 0.5%
                                </Text>
                            </Flex>

                            <Controller
                                control={control}
                                name='partyB'
                                render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                                    <Input
                                        h='57px'
                                        name={name}
                                        onChange={onChange}
                                        placeholder='0x000...0000'
                                        label={`Counterparty's Address`}
                                        value={value}
                                        error={error?.message} />
                                )} />
                            <Flex gap={{ sm: 2, md: 20, lg: 20, xl: 20 }} mt={2}>
                                <Box>
                                    <Controller
                                        control={control}
                                        name='currencyAddress'
                                        render={({ fieldState: { error } }) => (
                                            <FormControl isInvalid={!!error}>
                                                <FormLabel
                                                    color='gray.500'
                                                    fontSize='14px'
                                                    fontWeight={500}
                                                    lineHeight={1.43}
                                                    mb='4px'>
                                                    You send them
                                                </FormLabel>
                                                <Button
                                                    isDisabled={!isConnected}
                                                    onClick={onOpen}
                                                    leftIcon={selectedTokenA
                                                        ? <Image src={selectedTokenA.logoURI} h='30px' />
                                                        : <></>
                                                    }
                                                    rightIcon={<ChevronDownIcon />}
                                                    size='lg'>
                                                    { selectedTokenA && selectedTokenA.symbol }
                                                    { !currencyAddress && 'Select Token' }
                                                </Button>
                                                {error && (
                                                    <FormErrorMessage
                                                        color='error.500'
                                                        fontSize='14px'
                                                        lineHeight={1.22}>
                                                        {error.message}
                                                    </FormErrorMessage>
                                                )}
                                            </FormControl>
                                        )
                                        } />
                                    <Controller
                                        control={control}
                                        name='currencyAmount'
                                        render={({
                                            field: { name, onChange, value },
                                            fieldState: { error }
                                        }) => (
                                            <Input
                                                h='57px'
                                                type='number'
                                                name={name}
                                                onChange={onChange}
                                                label='Amount'
                                                value={value}
                                                error={error?.message}/>
                                        )} />
                                </Box>
                                <Box textAlign='end'>
                                    <Controller
                                        control={control}
                                        name='currencyAddressB'
                                        render={({
                                            fieldState: { error }
                                        }) => (
                                            <FormControl isInvalid={!!error}>
                                                <FormLabel
                                                    color='gray.500'
                                                    fontSize='14px'
                                                    fontWeight={500}
                                                    lineHeight={1.43}
                                                    mb='4px'
                                                    textAlign='right'>
                                                    They send you
                                                </FormLabel>
                                                <Button
                                                    isDisabled={!isConnected}
                                                    onClick={onOpenB}
                                                    leftIcon={selectedTokenB
                                                        ? <Image src={selectedTokenB.logoURI} h='30px' />
                                                        : <></>
                                                    }
                                                    rightIcon={<ChevronDownIcon />}
                                                    size='lg'>
                                                    { selectedTokenB && selectedTokenB.symbol }
                                                    { !currencyAddressB && 'Select Token' }
                                                </Button>
                                                {error && (
                                                    <FormErrorMessage
                                                        color='error.500'
                                                        fontSize='14px'
                                                        lineHeight={1.22}>
                                                        {error.message}
                                                    </FormErrorMessage>
                                                )}
                                            </FormControl>
                                        )
                                        } />
                                    <Controller
                                        control={control}
                                        name='currencyAmountB'
                                        render={({
                                            field: { name, onChange, value },
                                            fieldState: { error }
                                        }) => (
                                            <Input
                                                h='57px'
                                                type='number'
                                                name={name}
                                                onChange={onChange}
                                                label='Amount'
                                                labelAlign='right'
                                                value={value}
                                                error={error?.message}/>
                                        )} />
                                </Box>
                            </Flex>
                            <Flex gap='1' alignItems='center'>
                                { !needsToApprove && isConnected &&
                                    <Button
                                        borderRadius='10px'
                                        colorScheme='pink'
                                        isDisabled={!tokenContract
                                            || !currencyAmount
                                            || !spendApproved
                                            || !tokenContractB
                                            || !currencyAddressB
                                        }
                                        isLoading={buttonLoading}
                                        width='100%'
                                        p='16px'
                                        spinner={<Spinner />}
                                        size='xl'
                                        type='submit'>
                                        { !isConnected && 'Connect a wallet first' }
                                        { isConnected && !tokenContract && !tokenContractB && 'Select Tokens' }
                                        {tokenContract && (!currencyAmount || currencyAmount === 0) &&
                                            'Enter amount'
                                        }
                                        { currencyAmount && tokenContract && 'REQUEST SWAP' }
                                    </Button>
                                }
                                { needsToApprove && isConnected &&
                                    <Button
                                        borderRadius='10px'
                                        colorScheme='pink'
                                        isLoading={buttonLoading}
                                        isDisabled={!tokenContract}
                                        p='16px'
                                        type='submit'
                                        size={{ sm: 'sm', md: 'xl', lg: 'xl', xl: 'xl' }}
                                        spinner={<Spinner />}
                                        variant='outline'>
                                        Approve Spend
                                    </Button>
                                }
                                {!isConnected &&
                                    <Button
                                        borderRadius='10px'
                                        colorScheme='blue'
                                        width='100%'
                                        p='16px'
                                        onClick={onWCOpen}
                                        size='xl'>
                                        Connect Wallet
                                    </Button>
                                }
                            </Flex>
                        </Flex>
                    </form>
                </FormProvider>
            </Flex>
            {isConnected && !wrongChain &&
                <>
                    <CurrencyModal
                        isOpen={isOpen}
                        onClose={onClose}
                        onSelectCurrency={onSelectCurrencyA}
                        selectedCurrency={selectedTokenA} />
                    <CurrencyModal
                        isOpen={isOpenB}
                        onClose={onCloseB}
                        onSelectCurrency={onSelectCurrencyB}
                        selectedCurrency={selectedTokenB} />
                </>
            }
            {tweetAmount && tokenContract && tweetTagId && (
                <TweetModal
                    isOpen={isOpenT}
                    onClose={onCloseTweet}
                    amount={tweetAmount}
                    currencyName={selectedTokenA?.symbol}
                    tagId={tweetTagId} />
            )}
            <WalletConnectModal isOpen={isWCOpen} onClose={onWCClose} />
        </Box>
    )
}

export default CreateEscrowOtc;