import React from 'react';
import { Flex, Text, SimpleGrid } from '@chakra-ui/react';
import EscrowListEntry from './EscrowListEntry';
import { IEscrow } from '../../../helpers/constants';
import { TagViewCategories } from '../index';

type EscrowsListProps = {
    escrows: IEscrow[],
    category: TagViewCategories,
}

const EscrowsList: React.FC<EscrowsListProps> = ({ escrows, category  }) => {
    return (
        <Flex alignItems='center' direction='column'>
            { escrows.length === 0 && (
                <Text w='100%' color='gray.400' textAlign='center' pt='30px' userSelect='none'>
                    Nothing here. Start a new TAG.
                </Text>
            ) }
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacingX='20' spacingY='5'>
                {escrows
                    .map((escrow: IEscrow, idx: number) => (
                        <EscrowListEntry
                            chainEscrow={escrow}
                            category={category}
                            key={`${category}-${idx}-tag`}/>
                    ))}
            </SimpleGrid>
        </Flex>
    );
};

export default EscrowsList;