import React from 'react';
import CenteredViewContainer from '../components/Containers/CenteredViewContainer';
import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { AppRoute } from '../helpers/constants';
import ReactGA from 'react-ga4';
import { useLocation, useNavigate } from 'react-router-dom';
import formatDt from '../helpers/format/formatDt';

const missions = [
    { description: 'Do Kwon convicted before end of 2025', date: '2025-12-30T00:00' },
    { description: 'Tapioca will be #2 TVL on arbi based on Defillama, on June 30th', date: '2023-06-30T00:00' },
    { description: '$ARB > $3 end of June 30th, 2023', date: '2023-07-01T00:00' },
];

const Missions: React.FC = () => {
    const { search } = useLocation();
    const navigate = useNavigate();

    return (
        <CenteredViewContainer>
            <Flex w='100%' overflowWrap='break-word' alignItems='center' mb={10} gap='2' direction='column'>
                <Text fontSize='40px' color='gray.900'>
                    Mission TAGs
                </Text>
                <Box fontSize='18px' color='gray.400' textAlign='center'>
                    <Text>Create a TAG with one of the below items and get in line for potential future rewards.</Text>
                </Box>
            </Flex>
            <Flex>
                <SimpleGrid columns={[2, null, 3]} spacingX='3' spacingY='2'>
                    {missions.map((msn, idx) => (
                        <Box
                            key={`${idx}-mission`}
                            bg='white'
                            borderRadius='5px'
                            cursor='pointer'
                            px={3}
                            py={6}
                            position='relative'
                            w='200px'
                            h='180px'
                            sx={{
                                transition: 'box-shadow 0.3s linear'
                            }}
                            _hover={{
                                boxShadow: '0 1px 3px rgba(0,0,0,.6)'
                            }}
                            onClick={() => {
                                navigate(AppRoute.TAG + search, { state: { ...msn }});
                                ReactGA.event({
                                    category: 'MISSION',
                                    action: 'ClickedMission',
                                });
                            }}>
                            <Text position='absolute' top={1} color='gray.400' fontSize='sm'>
                                MISSION#{ idx + 1 }
                            </Text>
                            <Text position='absolute' top={7} color='gray.700'>
                                { msn.description }
                            </Text>
                            <Text position='absolute' bottom={1} color='gray.500' fontSize='xs'>
                                { formatDt(msn.date) }
                            </Text>
                        </Box>
                    ))}
                </SimpleGrid>
            </Flex>
        </CenteredViewContainer>
    )
}

export default Missions;
