import React, { useContext } from 'react';
import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text
} from '@chakra-ui/react';
import { Web3Context } from '../../providers/Web3Provider';
import { useNetwork } from 'wagmi';

const WrongNetworkModal: React.FC = () => {
    const { wrongChain, switchNetwork } = useContext(Web3Context);
    const { chains } = useNetwork();

    return (
        <Modal isCentered isOpen={wrongChain} onClose={() => { return; }} size='md'>
            <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(90deg)' />
            <ModalContent>
                <ModalHeader>
                    <Text color='black' textAlign='center' w='100%'>Wrong Network</Text>
                </ModalHeader>
                <ModalBody>
                    <Text textAlign='center' w='100%'>You cannot use this dapp on this network</Text>
                    <Text textAlign='center' w='100%' size='sm'>Click on the button below to switch</Text>
                </ModalBody>
                <ModalFooter w='100%' alignContent='center' alignItems='center'>
                    <Flex direction='column' alignContent='center' alignItems='center' w='100%' mb={2}>
                        <Text w='100%' textAlign='center' color='gray.400'>Switch to</Text>
                        {chains.map(chn => {
                            if (chn.testnet) {
                                return undefined;
                            }
                            return (
                                <Button
                                    key={chn.id}
                                    borderRadius='sm'
                                    colorScheme='pink'
                                    p='16px'
                                    w='100%'
                                    mb='1px'
                                    onClick={() => switchNetwork(chn.id)}>
                                    {chn.name}
                                </Button>
                            )
                        }
                        )}
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default WrongNetworkModal;
