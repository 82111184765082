import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Web3Context } from '../../providers/Web3Provider';
import { Button, ButtonGroup, Center, Spinner, Text } from '@chakra-ui/react';
import ViewContainer from '../../components/Containers/ViewContainer';
import OtcDescription from './OtcDescription';
import { IOTCEscrow } from '../../helpers/constants';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import OtcList from './OtcList';
import CreateOtc from './OtcSwapForm';

export enum OTCViewCategories {
    SWAP = 'Swap',
    OPEN = 'Open',
    DONE = 'Done'
}

function OTC() {
    const {
        appConfig,
        walletAddress,
        isConnected,
        signer,
        contracts: { escrowOtc },
        wrongChain
    } = useContext(Web3Context);
    const { pathname, search } = useLocation();
    const [escrows, setEscrows] = useState<IOTCEscrow[]>([]);
    const [loadingE, setLoadingE] = useState<boolean>(false);
    const [activeCategory, setActiveCategory] = useState<OTCViewCategories>(OTCViewCategories.SWAP);

    useEffect(() => {
        if (!isConnected) {
            setActiveCategory(OTCViewCategories.SWAP);
        }
    }, [isConnected])

    useEffect(() => {
        const page = pathname + search;
        ReactGA.send({ hitType: 'pageview', page });
    }, [pathname, search]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const loadEscrows = useCallback(async (address: string): Promise<void> => {
        if (!signer || !escrowOtc || wrongChain || !walletAddress) {
            return;
        }
        setLoadingE(true);
        const scrsPromises: Promise<any>[] = [];
        const otcEscrowIds: number[] = await escrowOtc.getEscrowsForAddress(address);
        otcEscrowIds.forEach(id => scrsPromises.push(escrowOtc.escrows(id)));
        const walletEscrows = await Promise.all(scrsPromises);
        setEscrows(walletEscrows);
        setLoadingE(false);
    }, [escrowOtc, signer, walletAddress, wrongChain]);

    useEffect(() => {
        if (walletAddress) {
            loadEscrows(walletAddress);
        }
    }, [appConfig, walletAddress, loadEscrows, wrongChain]);

    useEffect(() => {
        if (!isConnected) setActiveCategory(OTCViewCategories.SWAP);
    }, [isConnected])

    const openEscrows = useMemo(() => escrows.filter(escrow => escrow.started && !escrow.closed), [escrows]);
    const closedEscrows = useMemo(() => escrows.filter(escrow => escrow.started && escrow.closed), [escrows]);

    return (
        <ViewContainer>
            { !isConnected && <OtcDescription /> }
            <Center mb={4} fontSize={{ sm: 'xs' }}>
                <ButtonGroup borderRadius={10} boxShadow='0 0px 3px rgba(0, 0, 0, .3)'>
                    <Button
                        borderRadius={0}
                        borderLeftRadius={10}
                        colorScheme='pink'
                        variant={activeCategory === OTCViewCategories.SWAP ? 'solid' : 'ghost'}
                        onClick={() => setActiveCategory(OTCViewCategories.SWAP)}>
                        { OTCViewCategories.SWAP }
                    </Button>
                    <Button
                        borderRadius={0}
                        colorScheme={activeCategory === OTCViewCategories.OPEN ? 'teal' : 'gray'}
                        isDisabled={!isConnected}
                        onClick={() => setActiveCategory(OTCViewCategories.OPEN)}>
                        { OTCViewCategories.OPEN }
                        <Text size='xs' color={activeCategory === OTCViewCategories.OPEN ? 'gray.200' : 'gray.500'}>
                            { loadingE && <Spinner size='xs' /> }
                            { !loadingE && <>{'  '}[{openEscrows.length}]</> }
                        </Text>
                    </Button>
                    <Button
                        colorScheme={activeCategory === OTCViewCategories.DONE ? 'teal' : 'gray'}
                        borderRadius={0}
                        borderRightRadius={10}
                        isDisabled={!isConnected}
                        onClick={() => setActiveCategory(OTCViewCategories.DONE)}>
                        { OTCViewCategories.DONE }
                        <Text size='xs' color={activeCategory === OTCViewCategories.DONE ? 'gray.200' : 'gray.500'}>
                            { loadingE && <Spinner size='xs' /> }
                            { !loadingE && <>{'  '}[{closedEscrows.length}]</> }
                        </Text>
                    </Button>
                </ButtonGroup>
            </Center>

            {activeCategory === OTCViewCategories.SWAP &&  <CreateOtc /> }

            { isConnected && activeCategory !== OTCViewCategories.SWAP &&
                <OtcList
                    escrowsOtc={activeCategory === OTCViewCategories.OPEN ? openEscrows : closedEscrows}
                    category={activeCategory} />
            }
        </ViewContainer>
    );
}

export default OTC;
