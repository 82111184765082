import '@fontsource/montserrat/400-italic.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500-italic.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600-italic.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700-italic.css';
import '@fontsource/montserrat/700.css';

import '@fontsource/roboto/400-italic.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500-italic.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700-italic.css';
import '@fontsource/roboto/700.css';
import './styles/overwrite.css';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import chakraTheme from './styles';
import { Web3Provider } from './providers/Web3Provider';
import { HelmetProvider } from 'react-helmet-async';
import { ChakraProvider } from '@chakra-ui/react';
import buffer from 'buffer';
// -- VIEWS
import WrongNetworkModal from './components/Modals/WrongNetworkModal';
import AppRoutes from './AppRoutes';
import ReactGA from 'react-ga4';

window.Buffer = window.Buffer || buffer.Buffer;
ReactGA.initialize('G-BDH9KG28CJ');

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Suspense fallback='loading'>
            <ChakraProvider theme={chakraTheme} resetCSS={true}>
                <Web3Provider>
                    <HelmetProvider>
                        <AppRoutes />
                    </HelmetProvider>
                    <WrongNetworkModal />
                </Web3Provider>
            </ChakraProvider>
        </Suspense>
    </React.StrictMode>
);

// function sendToAnalytics({ id, name, value }) {
//     ga('send', 'event', {
//         eventCategory: 'Web Vitals',
//         eventAction: name,
//         eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
//         eventLabel: id, // id unique to current page load
//         nonInteraction: true, // avoids affecting bounce rate
//     });
// }


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
