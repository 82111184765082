import confetti from 'canvas-confetti';

const count = 200;
const defaults = {
    origin: { y: 0.7, z: 9999 }
};

function fire(particleRatio: number, opts: any) {
    confetti(Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio)
    }));
}

export default () => {
    fire(0.25, {
        spread: 26,
        startVelocity: 55,
        zIndex: 9999,
    });
    fire(0.2, {
        spread: 60,
        zIndex: 9999,
    });
    fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        zIndex: 9999,
    });
    fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
        zIndex: 9999,
    });
    fire(0.1, {
        spread: 120,
        startVelocity: 45,
        zIndex: 9999,
    });
}
