import { Button, ButtonProps, Flex, Icon } from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as WalletConnectIcon } from '../../assets/icons/logos/logo-walletconnet.svg';
import { ReactComponent as CoinbaseIcon } from '../../assets/icons/logos/logo-coinbase.svg';
import { ReactComponent as MetamaskIcon } from '../../assets/icons/logos/logo-metamask-2.svg';

// Based on the 'connector.name' provided by Web3Context
export enum WalletProvider {
    MetaMask = 'MetaMask',
    WalletConnect = 'WalletConnect',
    Coinbase = 'Coinbase Wallet'
}

interface WPBtnProps extends ButtonProps {
    provider?: WalletProvider;
}

export const walletProvider: Record<string, WalletProvider> = {
    [WalletProvider.MetaMask]: WalletProvider.MetaMask,
    [WalletProvider.WalletConnect]: WalletProvider.WalletConnect,
    [WalletProvider.Coinbase]: WalletProvider.Coinbase,
}

const walletProviderIcon: Record<WalletProvider, React.ReactElement> = {
    [WalletProvider.MetaMask]: <Icon as={MetamaskIcon} h='30px' w='30px' />,
    [WalletProvider.WalletConnect]: <Icon as={WalletConnectIcon} h='30px' w='30px' />,
    [WalletProvider.Coinbase]: <Icon as={CoinbaseIcon} h='30px' w='30px' />
}

export const WalletProviderButton: React.FC<WPBtnProps> = ({
    children,
    provider,
    ...props
}) => {
    return (
        <Button
            border='1px gray.600'
            borderWidth={1}
            borderRadius={10}
            bg='white'
            color='gray.800'
            cursor='pointer'
            fontSize={16}
            iconSpacing='8px'
            padding='16px'
            size='xl'
            sx={{
                '&:hover:not([disabled])': {
                    bg: 'white',
                    boxShadow: '0 0 4px rgba(0, 100, 100, .4)',
                    color: 'gray.700'
                },
            }}
            transition={{ bg: '0.2s linear', boxShadow: '0.8s ease-in' }}
            w='100%'
            {...props}>
            <Flex direction='row' gap='20px' width='100%' height='100%' alignItems='center'>
                {provider ? walletProviderIcon[provider] : undefined}
                {children}
            </Flex>
        </Button>
    );
};
