import { ethers } from 'ethers';
import { ZERO_ADDY } from './constants';
import { Chain } from '@wagmi/core/dist/declarations/src/types';

const getCurrencyDecimalsAndSymbol = async (tokenContract: ethers.Contract, chain: Chain) => {
    const decimals = tokenContract.address === ZERO_ADDY ? 18 : await tokenContract.decimals();
    const symbol = tokenContract.address === ZERO_ADDY
        ? chain.nativeCurrency?.symbol ?? 'Gas Token'
        : await tokenContract.symbol();
    return {
        symbol,
        decimals
    };
}

export default getCurrencyDecimalsAndSymbol;
