import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Image,
    Spacer,
    Spinner,
    Text,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ethers } from 'ethers';
import { erc20ABI, useNetwork } from 'wagmi';
import moment from 'moment/moment';
import capitalize from 'lodash/capitalize';
import { Web3Context } from '../../../providers/Web3Provider';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { z } from 'zod';
import Input from '../../../components/Form/Input';
import { useLocation, useNavigate } from 'react-router-dom';
import CurrencyModal from '../../../components/Modals/CurrencyModal';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { AppRoute, MAX_BPS, ZERO_ADDY } from '../../../helpers/constants';
import FeePopover from './FeePopover';
import getCurrencyDecimalsAndSymbol from '../../../helpers/getCurrencyDecimalsAndSymbol';
import TweetModal from '../../../components/Modals/TweetModal';
import ReactGA from 'react-ga4';
import WalletConnectModal from '../../../components/Modals/WalletConnectModal';
import confetti from '../../../helpers/confetti';

const schema = z.object({
    chainId: z.number().nullish(),
    arbitratorFeeBps: z.number().min(0).max(2000),
    description: z
        .string({ required_error: 'You need to set a description' }).max(120).min(10),
    partyB: z
        .string({ required_error: 'You need to set a counterparty (address)' })
        .refine(value => ethers.utils.isAddress(value),
            { message: 'This does not look like a valid Wallet address!'}
        ).nullish(),
    partyArbitrator: z
        .string({ required_error: 'We need a Arbitrator' })
        .refine(value => ethers.utils.isAddress(value),
            { message: 'This does not look like a valid Wallet address!' }
        ),
    currencyAddress:  z
        .string({ required_error: 'You need to set a currency to spend!' })
        .refine(value => ethers.utils.isAddress(value),
            { message: 'This does not look like a valid token' }),
    currencyAddressB:  z
        .string({ required_error: 'You need to set a currency to spend!' })
        .refine(value => ethers.utils.isAddress(value),
            { message: 'This does not look like a valid token' }),
    currencyAmount: z.coerce.number().min(0.01).step(0.01),
    currencyAmountB: z.coerce.number().min(0.01).step(0.01),
    determineTime: z.string().refine(
        value => moment(value) > moment(),
        { message: 'Determination time should be in the future' }
    )
});

type Schema = z.infer<typeof schema>;
const DEFAULT_ARB_BPS = 500;
const ARB_BPS = 200;

export interface ISelectToken {
    symbol: string;
    name: string;
    address: string;
    decimals: number;
    logoURI: string;
}

const CreateEscrow: React.FC = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const { chain } = useNetwork();
    const { state, search } = useLocation();
    const {
        appConfig, walletAddress, signer, isConnected, wrongChain, contracts: { escrow }
    } = useContext(Web3Context);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isWCOpen, onOpen: onWCOpen, onClose: onWCClose } = useDisclosure();
    const { isOpen: isOpenB, onOpen: onOpenB, onClose: onCloseB } = useDisclosure();
    const { isOpen: isOpenT, onOpen: onOpenT, onClose: onCloseT } = useDisclosure();

    const form = useForm<Schema>({
        resolver: zodResolver(schema),
        defaultValues: {
            arbitratorFeeBps: DEFAULT_ARB_BPS,
            partyArbitrator: process.env.REACT_APP_TAG_ARBITRATOR,
        }
    });
    const { control, getValues, watch, handleSubmit, setValue } = form;

    const [tagFee, setTagFee] = useState<number | null>(null);
    const [tokenContract, setTokenContract] = useState<ethers.Contract | null>(null);
    const [tokenContractB, setTokenContractB] = useState<ethers.Contract | null>(null);
    const [selectedTokenA, setSelectedTokenA] = useState<ISelectToken | null>(null);
    const [selectedTokenB, setSelectedTokenB] = useState<ISelectToken | null>(null);
    const [spendApproved, setSpendApproved] = useState<boolean>(false);
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);
    const [tweetTagId, setTweetTagId] = useState<number | null>(null);
    const [tweetAmount, setTweetAmount] = useState<string | null>(null);

    const arbitratorAddress = watch('partyArbitrator');
    const arbitratorFeeBps = watch('arbitratorFeeBps');
    const currencyAddress = watch('currencyAddress');
    const currencyAmount = watch('currencyAmount');
    const currencyAddressB = watch('currencyAddressB');
    const currencyAmountB = watch('currencyAmountB');
    const isDefaultArbitrator = arbitratorAddress === process.env.REACT_APP_TAG_ARBITRATOR;

    useEffect(() => {
        setTagFeeBps()
    }, [escrow]);

    useEffect(() => {
        if (!state) {
            return;
        }
        window.scrollTo(0, 0);
        setValue('description', state.description);
        setValue('determineTime', state.date);
    }, [state]);

    useEffect(() => {
        setValue('arbitratorFeeBps', isDefaultArbitrator ? DEFAULT_ARB_BPS : ARB_BPS)
    }, [isDefaultArbitrator]);

    useEffect(() => {
        setValue('chainId', chain?.id);
    } ,[chain]);

    const loadTokenAndApprovals = useCallback(async () => {
        if (!escrow || !signer) {
            return;
        }
        try {
            if (!ethers.utils.isAddress(currencyAddress)) {
                return;
            }
            setButtonLoading(true);
            let approvedSpend: boolean;
            const tokenC = new ethers.Contract(currencyAddress, erc20ABI, signer);
            if (currencyAddress === ZERO_ADDY) {
                approvedSpend = true;
            } else {
                const decimals = await tokenC.decimals();
                const allowance = await tokenC.allowance(walletAddress, escrow.address);
                const allowanceNum = Number(ethers.utils.formatUnits(`${allowance}`, decimals));
                approvedSpend = allowanceNum >= Number(currencyAmount);
            }

            setTokenContract(tokenC);
            setSpendApproved(approvedSpend);
        } catch (e) {
            setSelectedTokenA(null);
            setSpendApproved(false);
        } finally {
            setButtonLoading(false);
        }
    }, [escrow, currencyAddress, currencyAmount, signer, walletAddress, appConfig]);

    useEffect(() => {
        loadTokenAndApprovals();
    }, [currencyAddress, currencyAmount, loadTokenAndApprovals]);

    useEffect(() => {
        loadTokenB();
    }, [currencyAddressB, currencyAmountB]);

    const setTagFeeBps = async () => {
        if (!escrow) {
            return;
        }
        const tagFeeBps = (await escrow.tagFeeBps()).toNumber();
        setTagFee((tagFeeBps / MAX_BPS) * 100);
    }

    const onSelectCurrencyA = (currency: ISelectToken): void => {
        if (currency.address === selectedTokenA?.address) {
            return;
        }
        onClose();
        setSelectedTokenA(currency);
        setValue('currencyAddress', currency.address);
        if (!currencyAddressB) {
            setSelectedTokenB(currency);
            setValue('currencyAddressB', currency.address);
        }
    };

    const onSelectCurrencyB = (currency: ISelectToken) => {
        if (currency.address === selectedTokenB?.address) {
            return;
        }
        onCloseB();
        setSelectedTokenB(currency);
        setValue('currencyAddressB', currency.address);
    }

    const validateAmount = (value: number, isCurrencyA: boolean) => {
        if (!chain) {
            return false;
        }
        const key = isCurrencyA ? 'currencyAmount' : 'currencyAmountB';
        const currency = isCurrencyA ? currencyAddress : currencyAddressB;
        const defaultCurrency = appConfig[chain.id].defaultCoins.find((coin: any) => coin.address === currency);
        if (defaultCurrency) {
            const minV = defaultCurrency.min;
            if (Number(value) < minV) {
                form.setError(key, { message: `The minimum for this currency is ${minV}` });
                return false;
            }
        }
        return true;
    }

    const loadTokenB = useCallback(async () => {
        if (!escrow || !signer) {
            return;
        }
        if (!ethers.utils.isAddress(currencyAddressB)) {
            return;
        }
        const tokenC = new ethers.Contract(currencyAddressB, erc20ABI, signer);
        setTokenContractB(tokenC);
    }, [escrow, currencyAddressB, currencyAmountB, signer])

    const createEscrow = useCallback(async () => {
        if (!signer || !escrow || !spendApproved || !tokenContract || !tokenContractB || !chain || !walletAddress) {
            return;
        }
        try {
            setButtonLoading(true);
            const formData = getValues();
            const amount1Valid = validateAmount(formData.currencyAmount, true);
            const amount2Valid = validateAmount(formData.currencyAmountB, false);
            if (!amount1Valid || !amount2Valid) {
                return;
            }
            const payingWithGas = formData.currencyAddress === ZERO_ADDY;
            const { decimals } = await getCurrencyDecimalsAndSymbol(tokenContract, chain);
            const { decimals:  decimalsB } = await getCurrencyDecimalsAndSymbol(tokenContractB, chain);
            const paymentParams = {
                value: !payingWithGas
                    ? 0
                    : ethers.utils.parseEther(`${formData.currencyAmount}`)
            };
            const amount = ethers.utils.parseUnits(`${formData.currencyAmount}`, decimals);
            const amountB = ethers.utils.parseUnits(`${formData.currencyAmountB}`, decimalsB);
            const partyB = formData.partyB ?? ethers.constants.AddressZero;
            const tx = await escrow.createEscrow(
                partyB,
                formData.partyArbitrator,
                formData.arbitratorFeeBps,
                formData.description,
                moment(formData.determineTime).unix(),
                formData.currencyAddress,
                amount,
                formData.currencyAddressB,
                amountB,
                { ...paymentParams }
            );
            await tx.wait();
            const tagId = await escrow.nextEscrowId(); // WARNING: This may be wrong when we got a high volume
            toast({
                title: 'Successfully created!',
                description: `Placed wager for ${formData.currencyAmount} ${selectedTokenA?.symbol}`,
                status: 'success',
                duration: 9000,
                isClosable: false,
            });
            ReactGA.event({
                category: 'CREATE',
                action: 'CreatedTag',
                value: Number(formData.currencyAmount),
            });
            onOpenTweet(formData.currencyAmount.toString(), selectedTokenA?.symbol, tagId.toNumber() - 1);
            confetti();
        } catch (e: any) {
            toast({
                title: e?.reason,
                status: 'error',
                duration: 9000,
                isClosable: false,
            });
        } finally {
            setButtonLoading(false);
        }
    }, [
        chain, escrow, signer,
        spendApproved, walletAddress,
        tokenContract, tokenContractB,
        currencyAmount, currencyAmountB
    ]);

    const approveSpend = useCallback(async () => {
        if (!tokenContract || !walletAddress || !escrow) {
            return;
        }

        try {
            const formData = getValues();
            const amount1Valid = validateAmount(formData.currencyAmount, true);
            const amount2Valid = validateAmount(formData.currencyAmountB, false);
            if (!amount1Valid || !amount2Valid) {
                return;
            }
            const decimals = await tokenContract.decimals();
            const approveGwei = ethers.utils.parseUnits(`${currencyAmount}`, decimals);
            setButtonLoading(true);
            await (
                await tokenContract.approve(escrow.address, `${approveGwei}`)
            ).wait();
            setButtonLoading(false);
            setSpendApproved(true);
            toast({
                title: 'Approved spend. Now you can TAG',
                status: 'success',
                duration: 7000,
                isClosable: false,
            });
            ReactGA.event({
                category: 'CREATE',
                action: 'CreatedTag',
            });
        } catch (e) {
            setButtonLoading(false);
        }
    }, [escrow, tokenContract, currencyAmount, currencyAmountB, selectedTokenB, selectedTokenB, walletAddress]);

    const onOpenTweet = (amount: string, currencyName: string | undefined, tagId: number) => {
        setTweetAmount(amount);
        setTweetTagId(tagId);
        onOpenT();
    }

    const onCloseTweet = () => {
        setTweetAmount(null);
        setTweetTagId(null);
        onCloseT();
        navigate(AppRoute.TAG + search, { replace: true });
    }

    const needsToApprove = useMemo(() => !spendApproved && tokenContract && currencyAmount > 0,
        [spendApproved, tokenContract, currencyAmount]);

    const isNotLatestV = useMemo(() => search.includes('version=1'), [search]);

    return (
        <>
            <Flex
                alignItems='center'
                background='white'
                borderRadius={8}
                border='1px solid gray.300'
                direction='column'
                gap='1'
                maxW={450}
                minW={350}
                m='auto'
                p='16px'>
                <FormProvider {...form}>
                    <form
                        onSubmit={handleSubmit(spendApproved ? createEscrow : approveSpend)}
                        style={{ width: '100%' }}>
                        <Flex direction='column' gap='2' w='100%'>
                            <Flex textAlign='left' color='gray.50' direction='row' mb={2}>
                                <Text fontSize='16px' fontWeight='bold' color='gray.600'>
                                    Create TAG
                                </Text>
                                <Spacer />
                                <Text fontSize='13px' textAlign='right'>
                                    { tagFee && <>Platform Fee: {tagFee}%</> }
                                </Text>
                            </Flex>
                            <Controller
                                control={control}
                                name='description'
                                render={({
                                    field: { name, onChange, value },
                                    fieldState: { error }
                                }) => (
                                    <Input
                                        h='57px'
                                        name={name}
                                        placeholder='e.g. The Mavericks are going to win the 2023 Superbowl'
                                        onChange={onChange}
                                        label={capitalize(name)}
                                        value={value}
                                        error={error?.message} />
                                )} />
                            <Controller
                                control={control}
                                name='partyB'
                                render={({
                                    field: { name, onChange, value },
                                    fieldState: { error }
                                }) => (
                                    <Input
                                        h='57px'
                                        name={name}
                                        onChange={onChange}
                                        placeholder='0x000...0000'
                                        label={`Counterparty (leave blank for open bet)`}
                                        value={value ?? ''}
                                        error={error?.message} />
                                )} />
                            <Controller
                                control={control}
                                name='partyArbitrator'
                                render={({
                                    field: { name, onChange, value },
                                    fieldState: { error }
                                }) => (
                                    <>
                                        <Input
                                            h='57px'
                                            name={name}
                                            onChange={onChange}
                                            label='Arbitrator'
                                            placeholder='Set your own Arbitrator or use predefined'
                                            value={value}
                                            error={error?.message} />
                                        <Box>
                                            <FeePopover
                                                isDefaultArbitrator={isDefaultArbitrator}
                                                feeBps={isDefaultArbitrator ? DEFAULT_ARB_BPS : arbitratorFeeBps}
                                                onChangeFee={bps => setValue('arbitratorFeeBps', bps)}>
                                                <Text
                                                    cursor='pointer'
                                                    color='rose.400'
                                                    size='sm'
                                                    textDecoration='underline'
                                                    textAlign='right'
                                                    userSelect='none'>
                                                    Their Fee: {isDefaultArbitrator
                                                        ? (DEFAULT_ARB_BPS / MAX_BPS) * 100
                                                        : (arbitratorFeeBps / MAX_BPS) * 100}%
                                                </Text>
                                            </FeePopover>
                                        </Box>
                                    </>
                                )} />
                            <Flex gap={{ sm: 2, md: 20, lg: 20, xl: 20 }} mt={5}>
                                <Box>
                                    <Controller
                                        control={control}
                                        name='currencyAddress'
                                        render={({
                                            fieldState: { error }
                                        }) => (
                                            <FormControl isInvalid={!!error}>
                                                <FormLabel
                                                    color='gray.500'
                                                    fontSize='14px'
                                                    fontWeight={500}
                                                    lineHeight={1.43}
                                                    mb='2px'>
                                                    You deposit
                                                </FormLabel>
                                                <Button
                                                    isDisabled={!isConnected}
                                                    onClick={onOpen}
                                                    leftIcon={selectedTokenA
                                                        ? <Image src={selectedTokenA.logoURI} h='30px' />
                                                        : <></>
                                                    }
                                                    rightIcon={<ChevronDownIcon />}
                                                    size='lg'>
                                                    { selectedTokenA && selectedTokenA.symbol }
                                                    { !currencyAddress && 'Select Token' }
                                                </Button>
                                                {error && (
                                                    <FormErrorMessage
                                                        color='error.500'
                                                        fontSize='14px'
                                                        lineHeight={1.22}>
                                                        {error.message}
                                                    </FormErrorMessage>
                                                )}
                                            </FormControl>
                                        )
                                        } />
                                    <Controller
                                        control={control}
                                        name='currencyAmount'
                                        render={({
                                            field: { name, onChange, value },
                                            fieldState: { error }
                                        }) => (
                                            <Input
                                                h='57px'
                                                type='number'
                                                name={name}
                                                onChange={onChange}
                                                label='Amount'
                                                value={value}
                                                error={error?.message}/>
                                        )} />
                                </Box>
                                <Box textAlign='end'>
                                    <Controller
                                        control={control}
                                        name='currencyAddressB'
                                        render={({
                                            fieldState: { error }
                                        }) => (
                                            <FormControl isInvalid={!!error}>
                                                <FormLabel
                                                    color='gray.500'
                                                    fontSize='14px'
                                                    fontWeight={500}
                                                    lineHeight={1.43}
                                                    mb='2px'
                                                    textAlign='right'>
                                                    They deposit
                                                </FormLabel>
                                                <Button
                                                    isDisabled={!isConnected}
                                                    onClick={onOpenB}
                                                    leftIcon={selectedTokenB
                                                        ? <Image src={selectedTokenB.logoURI} h='30px' />
                                                        : <></>
                                                    }
                                                    rightIcon={<ChevronDownIcon />}
                                                    size='lg'>
                                                    { selectedTokenB && selectedTokenB.symbol }
                                                    { !currencyAddressB && 'Select Token' }
                                                </Button>
                                                {error && (
                                                    <FormErrorMessage
                                                        color='error.500'
                                                        fontSize='14px'
                                                        lineHeight={1.22}>
                                                        {error.message}
                                                    </FormErrorMessage>
                                                )}
                                            </FormControl>
                                        )
                                        } />
                                    <Controller
                                        control={control}
                                        name='currencyAmountB'
                                        render={({
                                            field: { name, onChange, value },
                                            fieldState: { error }
                                        }) => (
                                            <Input
                                                h='57px'
                                                type='number'
                                                name={name}
                                                onChange={onChange}
                                                label='Amount'
                                                labelAlign='right'
                                                value={value}
                                                error={error?.message}/>
                                        )} />
                                </Box>

                            </Flex>
                            <Controller
                                control={control}
                                name='determineTime'
                                render={({
                                    fieldState: { error },
                                    field: { name, onChange, value }
                                }) => (
                                    <Box>
                                        <Input
                                            h='57px'
                                            min={moment().format('')}
                                            name={name}
                                            onChange={onChange}
                                            label='Arbitration date & time'
                                            type='datetime-local'
                                            value={value}
                                            error={error?.message}/>
                                        <Text w='100%' textAlign='right'>
                                            Timezone: { Intl.DateTimeFormat().resolvedOptions().timeZone }
                                        </Text>
                                    </Box>
                                )} />
                            <Flex gap='1' alignItems='center' width='100%'>
                                { !needsToApprove && !isNotLatestV &&
                                    <>
                                        {isConnected  &&
                                            <Button
                                                borderRadius='10px'
                                                colorScheme='pink'
                                                isDisabled={!tokenContract || !currencyAmount || !spendApproved}
                                                isLoading={buttonLoading}
                                                width='100%'
                                                p='16px'
                                                spinner={<Spinner />}
                                                size='xl'
                                                type='submit'>
                                                { isConnected && !tokenContract && 'Select Token' }
                                                {tokenContract && (!currencyAmount || currencyAmount === 0) &&
                                                    'Enter amount'
                                                }
                                                { currencyAmount && tokenContract && 'TAG' }
                                            </Button>
                                        }
                                        {!isConnected &&
                                            <Button
                                                borderRadius='10px'
                                                colorScheme='blue'
                                                width='100%'
                                                p='16px'
                                                onClick={onWCOpen}
                                                size='xl'>
                                                Connect Wallet
                                            </Button>
                                        }
                                    </>
                                }
                                { needsToApprove && !isNotLatestV &&
                                    <Button
                                        borderRadius='10px'
                                        colorScheme='pink'
                                        isLoading={buttonLoading}
                                        isDisabled={!tokenContract}
                                        width='100%'
                                        p='16px'
                                        type='submit'
                                        size='xl'
                                        spinner={<Spinner />}
                                        variant='outline'>
                                        Approve Spend
                                    </Button>
                                }
                                { isNotLatestV &&
                                    <Button
                                        borderRadius='10px'
                                        colorScheme='blackAlpha'
                                        isDisabled
                                        width='100%'
                                        p='16px'
                                        type='submit'
                                        size='xl'>
                                        Cannot create v1 TAG
                                    </Button>
                                }
                            </Flex>
                        </Flex>
                    </form>
                </FormProvider>
            </Flex>
            { isConnected && !wrongChain &&
                <>
                    <CurrencyModal
                        isOpen={isOpen}
                        onClose={onClose}
                        onSelectCurrency={onSelectCurrencyA}
                        selectedCurrency={selectedTokenA} />
                    <CurrencyModal
                        isOpen={isOpenB}
                        onClose={onCloseB}
                        onSelectCurrency={onSelectCurrencyB}
                        selectedCurrency={selectedTokenB} />
                </>
            }
            {tweetAmount && tokenContract && tweetTagId && (
                <TweetModal
                    isOpen={isOpenT}
                    onClose={onCloseTweet}
                    amount={tweetAmount}
                    currencyName={selectedTokenA?.symbol}
                    tagId={tweetTagId} />
            )}
            <WalletConnectModal isOpen={isWCOpen} onClose={onWCClose} />
        </>
    )
}

export default CreateEscrow;
