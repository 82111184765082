import MumbaiTokens from '../assets/tokenLists/mumbai.json';
import Sepoliatokens from '../assets/tokenLists/sepolia.json';
import ArbitrumTokens from '../assets/tokenLists/arb.json';
import EthTokens from '../assets/tokenLists/eth.json';
import { ReactComponent as LogoPolygon } from '../assets/icons/logos/logo-polygon.svg';
import { ReactComponent as LogoEth } from '../assets/icons/logos/logo-eth.svg';
import { ReactComponent as LogoArbi } from '../assets/icons/logos/logo-arbitrum.svg';


interface INetworkConfig {
    defaultCoins: any[],
    logo: any;
    escrowContractAddress: string;
    nftContractAddress: string;
    escrowOtcAddress: string;
}

export interface INetworks {
    [key: number]:  INetworkConfig,
    version: number
}

export const APP_NETWORK_CONFIG_v1: INetworks = {
    version: 1,
    1: { // ETH
        defaultCoins: EthTokens,
        logo: LogoEth,
        escrowContractAddress: '0xC5d57c85343Cf86c7F13eC25D1cDF316b90576ad',
        nftContractAddress: '0x2bA8333df6599CC6afdF2F3a8090870B9B24c53A',
        escrowOtcAddress: '0xB2a1c27ADbEB3fAde144bD586A2087C565431773',
    },
    42161: { // Arbitrum
        defaultCoins: ArbitrumTokens,
        logo: LogoArbi,
        escrowContractAddress: '0x2bA8333df6599CC6afdF2F3a8090870B9B24c53A',
        nftContractAddress: '0x1D023C6E9e9A5623dEF1ea0Bc94ac79d8CFd0E1E',
        escrowOtcAddress: '0x832e32877555c7D10C296346365824a97c16Ebe2',
    },
    11155111: { // SEPOLIA
        defaultCoins: Sepoliatokens,
        logo: LogoEth,
        escrowContractAddress: '0xEB99037C87F2Ae01688bB70f4b4b708741e92295',
        nftContractAddress: '0xb609C2A57DF4D9e93bBc27d23C0a9373014067F6',
        escrowOtcAddress: '',
    },
    80001: { // MUMBAI POLYGON
        defaultCoins: MumbaiTokens,
        logo: LogoPolygon,
        escrowContractAddress: '0x1406bb624C1Bcc4510E10465B8997fF230b14049',
        nftContractAddress: '0x8b040444BeE76ff5E391Cfe4a447D1d65120a413',
        escrowOtcAddress: '0xcc6e5166063D17e6025e2E0e7b1510E4640FaC45',
    }
}

export const APP_NETWORK_CONFIG_v2: INetworks = {
    version: 2,
    1: { // ETH
        defaultCoins: EthTokens,
        logo: LogoEth,
        escrowContractAddress: '0xB2Ac2EE138b1E90c70C9C816315A2d13Ed875FD2',
        nftContractAddress: '0x2bA8333df6599CC6afdF2F3a8090870B9B24c53A',
        escrowOtcAddress: '0xB2a1c27ADbEB3fAde144bD586A2087C565431773',
    },
    42161: { // Arbitrum
        defaultCoins: ArbitrumTokens,
        logo: LogoArbi,
        escrowContractAddress: '0xbe4b930383c608Ae1BcBFc079FfE9F7cF4616d12',
        nftContractAddress: '0x1D023C6E9e9A5623dEF1ea0Bc94ac79d8CFd0E1E',
        escrowOtcAddress: '0x832e32877555c7D10C296346365824a97c16Ebe2',
    },
    11155111: { // SEPOLIA
        defaultCoins: Sepoliatokens,
        logo: LogoEth,
        escrowContractAddress: '0xEB99037C87F2Ae01688bB70f4b4b708741e92295',
        nftContractAddress: '0xb609C2A57DF4D9e93bBc27d23C0a9373014067F6',
        escrowOtcAddress: '',
    },
    80001: { // MUMBAI POLYGON
        defaultCoins: MumbaiTokens,
        logo: LogoPolygon,
        escrowContractAddress: '0xf26c568103f17BB7C9e289044e50b6c64e31f17C',
        nftContractAddress: '0x8b040444BeE76ff5E391Cfe4a447D1d65120a413',
        escrowOtcAddress: '0xcc6e5166063D17e6025e2E0e7b1510E4640FaC45',
    }
}

export const APP_NETWORK_CONFIG: INetworks = {
    version: 3,
    1: { // ETH
        defaultCoins: EthTokens,
        logo: LogoEth,
        escrowContractAddress: '0x8e98ca699D1614612b62Adc11bcbF4A8177F5d78',
        nftContractAddress: '0x2bA8333df6599CC6afdF2F3a8090870B9B24c53A',
        escrowOtcAddress: '0xB2a1c27ADbEB3fAde144bD586A2087C565431773',
    },
    42161: { // Arbitrum
        defaultCoins: ArbitrumTokens,
        logo: LogoArbi,
        escrowContractAddress: '0xC6bF9124B6146965aA1F2f6769b87abB13ca734e',
        nftContractAddress: '0x1D023C6E9e9A5623dEF1ea0Bc94ac79d8CFd0E1E',
        escrowOtcAddress: '0x832e32877555c7D10C296346365824a97c16Ebe2',
    },
    11155111: { // SEPOLIA
        defaultCoins: Sepoliatokens,
        logo: LogoEth,
        escrowContractAddress: '0xEB99037C87F2Ae01688bB70f4b4b708741e92295',
        nftContractAddress: '0xb609C2A57DF4D9e93bBc27d23C0a9373014067F6',
        escrowOtcAddress: '',
    },
    80001: { // MUMBAI POLYGON
        defaultCoins: MumbaiTokens,
        logo: LogoPolygon,
        escrowContractAddress: '0xf26c568103f17BB7C9e289044e50b6c64e31f17C',
        nftContractAddress: '0x8b040444BeE76ff5E391Cfe4a447D1d65120a413',
        escrowOtcAddress: '0xcc6e5166063D17e6025e2E0e7b1510E4640FaC45',
    }
}
