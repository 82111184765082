import React, { useContext, useEffect, useState } from 'react';
import { Web3Context } from '../../../providers/Web3Provider';
import {
    Button,
    Tr,
    Td,
    Stack,
    Text,
    Tooltip
} from '@chakra-ui/react';
import moment from 'moment/moment';
import { ethers } from 'ethers';
import { determineOutcome } from '../../../helpers/escrowActions';
import { IEscrow, MAX_BPS } from '../../../helpers/constants';
import { erc20ABI, useNetwork } from 'wagmi';
import getCurrencyDecimalsAndSymbol from '../../../helpers/getCurrencyDecimalsAndSymbol';
import formatDt from '../../../helpers/format/formatDt';

interface IArbitrateEscrow {
    chainEscrow: IEscrow;
}

const ArbitrateEntry: React.FC<IArbitrateEscrow> = ({ chainEscrow }) => {
    const { chain } = useNetwork();
    const { walletAddress, signer, contracts: { escrow } } = useContext(Web3Context);
    const [btnLoad, setButtonLoad] = useState<boolean>(false);
    const [formattedAmountA, setFormattedAmountA] = useState<string>('');
    const [formattedAmountB, setFormattedAmountB] = useState<string>('');
    const [formattedArbitratorFeeA, setFormattedArbitratorFeeA] = useState<string>('');
    const [formattedArbitratorFeeB, setFormattedArbitratorFeeB] = useState<string>('');
    const [momentNow, setMomentNow] = useState<number>(moment().unix());
    const escrowId = chainEscrow.escrowId.toNumber();
    const canDetermine = momentNow > chainEscrow.determineTime.toNumber();
    const arbitratorFee = (chainEscrow.arbitratorFeeBps.toNumber() / MAX_BPS) * 100;

    useEffect(() => {
        loadEscrowDetails();
    }, [walletAddress, escrow, signer, chainEscrow]);

    useEffect(() => {
        setInterval(() => {
            setMomentNow(moment().unix());
        }, 3000);
    }, []);

    const loadEscrowDetails = async () => {
        if (!escrow || !signer || !chain) {
            return;
        }
        const escrowId = chainEscrow.escrowId;
        const assetsA = await escrow.escrowAssetsA(escrowId);
        const assetsB = chainEscrow.pendingAssetB;
        const currencyA = new ethers.Contract(assetsA.currency, erc20ABI, signer);
        const currencyB = new ethers.Contract(assetsB.currency, erc20ABI, signer);
        const { symbol: symbolA, decimals: decimalsA } = await getCurrencyDecimalsAndSymbol(currencyA, chain);
        const { symbol: symbolB, decimals: decimalsB } = await getCurrencyDecimalsAndSymbol(currencyB, chain);
        const arbitratorFeeA = ethers.utils.formatUnits(
            assetsA.amount.mul(chainEscrow.arbitratorFeeBps).div(MAX_BPS), decimalsA);
        const arbitratorFeeB = ethers.utils.formatUnits(
            assetsB.amount.mul(chainEscrow.arbitratorFeeBps).div(MAX_BPS), decimalsB);
        const amountA = ethers.utils.formatUnits(`${assetsA.amount}`, decimalsA);
        const amountB = ethers.utils.formatUnits(`${assetsB.amount}`, decimalsB);
        setFormattedAmountA(amountA + ' ' + symbolA);
        setFormattedAmountB(amountB + ' ' + symbolB);
        setFormattedArbitratorFeeA(arbitratorFeeA + ' ' + symbolA);
        setFormattedArbitratorFeeB(arbitratorFeeB + ' ' + symbolB);
    }

    const onDetermineOutcome = async (escrowId: number, partyAWon: boolean, escrow: any) => {
        setButtonLoad(true);
        await determineOutcome(escrowId, partyAWon, escrow);
        setButtonLoad(true);
    }

    return (
        <Tr>
            <Td>{escrowId}</Td>
            <Td>
                <Tooltip label={chainEscrow.description}>
                    <Text overflow='hidden' textOverflow='ellipsis' whiteSpace='normal' minWidth='200px'>
                        {chainEscrow.description}
                    </Text>
                </Tooltip>
            </Td>
            {escrow && canDetermine && (
                <Td>
                    <Stack>
                        <Button
                            bg='button.action'
                            borderRadius={5}
                            color='white'
                            isLoading={btnLoad}
                            isDisabled={btnLoad}
                            _hover={{
                                bg: 'tagmi.logo',
                            }}
                            _focus={{
                                bg: 'tagmi.logo',
                            }}
                            size='sm'
                            onClick={() => onDetermineOutcome(escrowId, true, escrow)}>
                            FOR won
                        </Button>
                        <Button
                            mt={10}
                            bg='button.action'
                            borderRadius={5}
                            color='white'
                            isLoading={btnLoad}
                            isDisabled={btnLoad}
                            _hover={{
                                bg: 'tagmi.logo',
                            }}
                            _focus={{
                                bg: 'tagmi.logo',
                            }}
                            size='sm'
                            onClick={() => onDetermineOutcome(escrowId, false, escrow)}>
                            AGAINST won
                        </Button>
                    </Stack>
                </Td>
            )}
            {!canDetermine && (
                <Td>
                    <Stack>
                        <Text>You can determine the outcome at</Text>
                        <Text>{formatDt(moment.unix(chainEscrow.determineTime.toNumber()))}</Text>
                    </Stack>
                </Td>
            )}
            <Td>
                <Text fontWeight='bold'>{arbitratorFee}%</Text>
            </Td>
            <Td>
                <Text>{formattedAmountA}</Text>
                <Text fontSize='xs'>fee {formattedArbitratorFeeA}</Text>
            </Td>
            <Td>
                <Text>{formattedAmountB}</Text>
                <Text fontSize='xs'>fee {formattedArbitratorFeeB}</Text>
            </Td>
        </Tr>
    );
}

export default ArbitrateEntry;
