import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const OtcDescription: React.FC = () => {

    return (
        <Flex w='100%' alignItems='center' mt={25} mb={10} gap='2' direction='column' overflowWrap='break-word'>
            <Text fontSize='40px' color='gray.900'>
                Permissionless OTC swap of any token
            </Text>
            <Box fontSize='18px' color='gray.400' textAlign='center'>
                <Text>Instantly swap any ERC20 token with a counterparty</Text>
            </Box>
        </Flex>
    );
}

export default OtcDescription;
