import { Flex, Hide, Show, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import WalletConnectModal from '../Modals/WalletConnectModal';
import MHeader from './MHeader';
import BaseHeader from './BaseHeader';

const Header: React.FC = () => {
    const { isOpen: isWCOpen, onOpen: onWCOpen, onClose: onWCClose } = useDisclosure();

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Flex
            alignItems='center'
            boxShadow={scrollPosition > 92/3 ? '0 1px 3px rgba(0, 0, 0, .4)': 'unset'}
            bg={scrollPosition > 92/3 ? 'white' : 'transparent'}
            flexGrow={1}
            mb={7}
            mt={0}
            px={2}
            py={4}
            position='sticky'
            top={0}
            w='100%'
            zIndex={4}
            sx={{ transition: 'background 0.1s ease-in-out' }}>
            <Hide below='sm'>
                <BaseHeader onOpen={onWCOpen}/>
            </Hide>
            <Show below='sm'>
                <MHeader />
            </Show>
            <WalletConnectModal isOpen={isWCOpen} onClose={onWCClose} />
        </Flex>
    )
}

export default Header;

