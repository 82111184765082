import React, { useContext, useMemo } from 'react';
import { useNetwork } from 'wagmi';
import {
    Button,
    Flex, Hide,
    Icon,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger, Spacer,
    Text, useDisclosure
} from '@chakra-ui/react';
import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Web3Context } from '../../providers/Web3Provider';

const NetworkPicker: React.FC = () => {
    const { chain, chains } = useNetwork();
    const { switchNetwork, appConfig } = useContext(Web3Context);
    const { onClose, isOpen, onOpen } = useDisclosure();

    const unSupportedChain = useMemo(() => chain?.unsupported ?? true, [chain]);
    return (
        <>
            <Popover isOpen={isOpen} onClose={onClose}>
                <PopoverTrigger>
                    <Button
                        bg='transparent'
                        onClick={onOpen}
                        mr='20px'
                        leftIcon={(chain && !unSupportedChain)
                            ? <Icon as={appConfig[chain?.id].logo} h='30px' w='30px' />
                            : <></>}
                        rightIcon={<ChevronDownIcon />}>
                        <Hide below='md'>
                            { chain && chain.name }
                            { unSupportedChain && 'Unsupported' }
                        </Hide>
                    </Button>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverArrow />
                    <PopoverBody>
                        <Flex direction='column'>
                            { chains.map(chn => {
                                if (chn.testnet) {
                                    return undefined;
                                }
                                return (
                                    <Flex
                                        cursor='pointer'
                                        direction='row'
                                        p='5px'
                                        key={chn.id}
                                        onClick={() => {
                                            switchNetwork(chn.id);
                                            onClose();
                                        }}
                                        _hover={{
                                            bg: 'tagmi.light',
                                        }}
                                        w='100%'>
                                        <Icon
                                            as={appConfig[chn.id].logo}
                                            mr='12px'
                                            h='20px'
                                            w='20px'
                                            flex='1'/>
                                        <Text color='gray.900' flex='4'>
                                            {chn.name}
                                        </Text>
                                        <Spacer/>
                                        {chn.id === chain?.id && <CheckIcon mt='5px' color='tagmi.dark'/>}
                                    </Flex>
                                );
                            }
                            )}
                        </Flex>
                    </PopoverBody>
                </PopoverContent>
            </Popover>

        </>
    )
};

export default NetworkPicker;
