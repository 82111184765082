const formatDescriptionToSvgText = (description: string): string => {
    const words = description.trim().split(' ');
    const grouped = words.reduce((acc: string[], word: string, idx: number) => {
        const accLen = acc.length;
        if (idx % 3 === 0) {
            acc.push(word);
        } else {
            acc[accLen - 1] += ' ' + word;
        }
        return acc;
    }, []);
    return grouped.reduce((acc: string, joined: string) => {
        acc += `<tspan x="50%" dy="1.2em">${joined}</tspan>`
        return acc;
    }, '');
};

export default formatDescriptionToSvgText;
