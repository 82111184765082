import { IEscrow } from './constants';
import { ethers } from 'ethers';

const getTagOwnerAddress = async (escrow: IEscrow, nftContract: ethers.Contract, isPro = true) => {
    if (escrow.started) {
        return await nftContract.ownerOf(isPro ? escrow.nftA : escrow.nftB);
    }
    return isPro ? escrow.partyA : escrow.partyB;
}

export default getTagOwnerAddress;
