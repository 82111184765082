import { Heading } from '@chakra-ui/react';
import React from 'react';

const FooterHeading: React.FC<{ children: any }> = props => (
    <Heading
        as='h5'
        color='secondary'
        fontSize='14px'>
        {props.children}
    </Heading>
);

export default FooterHeading;
