import formatDescriptionToSvgText from './format/formatDescriptionToSvgText';
import { IEscrow, IFormattedAsset, MAX_BPS } from './constants';
import { ethers } from 'ethers';
import moment from 'moment';

const W = 290;
const H = 500;
const generateNftSvg = (
    escrow: IEscrow,
    tokenA: IFormattedAsset,
    tokenB: IFormattedAsset,
    isPartyA: boolean
): string => {
    const { arbitratorFeeBps, description, determineTime, escrowId, partyArbitrator } = escrow;
    const resolveTime = moment.unix(determineTime.toNumber()).format('YYYY-MM-DD HH:MM A') + ' (UTC)';

    const arbitratorFee = (arbitratorFeeBps.toNumber() / MAX_BPS) * 100;
    const fill = isPartyA ? '#0d863f' : '#ff0000';
    const formatedAmountA = Number(
        ethers.utils.formatUnits(`${tokenA.amount}`, tokenA.decimals)
    ).toLocaleString('en-US');
    const formatedAmountB = Number(
        ethers.utils.formatUnits(`${tokenB.amount}`, tokenB.decimals)
    ).toLocaleString('en-US');

    return `
<svg xmlns="http://www.w3.org/2000/svg" width="${W}" height="${H}" viewBox="0 0 ${W} ${H}" fill="${fill}">
    <defs>
        <filter id="top-region-blur">
            <feGaussianBlur in="SourceGraphic" stdDeviation="100"/>
        </filter>
        <linearGradient id="grad-symbol">
            <stop offset="0.7" stop-color="white" stop-opacity="1"/>
            <stop offset=".95" stop-color="white" stop-opacity="0"/>
        </linearGradient>
        <clipPath xmlns="http://www.w3.org/2000/svg" id="corners">
            <rect width="${W}" height="${H}" rx="22" ry="22"/>
        </clipPath>
        <path id="text-path-a" 
        d="M40 12 H250 A28 28 0 0 1 278 40 V460 A28 28 0 0 1 250 488 H40 A28 28 0 0 1 12 460 V40 A28 28 0 0 1 40 12 z"/>
    </defs>

    <g clip-path="url(#corners)">
        <rect fill="a0b869" x="0px" y="0px" width="${W}" height="500px"/>
        <rect style="filter: url(#f1)" x="0px" y="0px" width="290px" height="500px"/>
        <g style="filter:url(#top-region-blur); transform:scale(1.5); transform-origin:center top;">
            <rect fill="none" x="0px" y="0px" width="290px" height="500px"/>
            <ellipse cx="50%" cy="0px" rx="180px" ry="120px" fill="#000" opacity="0.85"/>
        </g>
    </g>
    
    <g style="transform:translate(0px, 0px);" dominant-baseline="middle" text-anchor="middle">
        <text x="50%" y="1em" 
             text-decoration="underline"
             font-family="'Courier New', monospace" font-size="20px" fill="white">
            <tspan>
                TAG#${escrowId.toNumber()}
            </tspan>
        </text>
    </g>
    
    <g style="transform:translate(0, 60px)" 
       font-family="'Courier New', monospace" font-size="16px" font-weight="bold"
       dominant-baseline="middle" text-anchor="middle">
        <text x="50%" y="0px">
            <tspan fill="white">
                ${formatedAmountA} ${tokenA.symbol}    
            </tspan>
        </text>
        <text x="50%" y="23px">
            <tspan fill="white">
                vs    
            </tspan>
        </text>
        <text x="50%" y="45px">
            <tspan fill="white">
                ${formatedAmountB} ${tokenB.symbol}    
            </tspan>
        </text>
    </g>

    <g style="transform:translate(0px, 145px);" dominant-baseline="middle" text-anchor="middle">
        <rect fill="rgba(0, 0, 0, .6)" 
              x="20px" y="0px"
              rx="8px" ry="8px" 
              width="${W - 40}px" 
              height="126px" />
        <text fill="white"
              font-family="'Courier New', monospace" font-weight="200" font-size="14px">
            ${formatDescriptionToSvgText(description)}
        </text>
    </g>
    
    <g style="transform:translate(0px, 330px);" dominant-baseline="middle" text-anchor="middle">
        <text fill="white"
              x="50%"
              font-family="'Courier New', monospace" 
              font-weight="200" 
              font-size="24px">
            ${isPartyA ? 'FOR' : 'AGAINST'}
        </text>
    </g>
    
    
    <g style="transform:translate(0, 390px)" dominant-baseline="middle" text-anchor="middle">
        <rect x="20px" y="0px" width="${W - 40}px"  height="55px" fill="rgba(0,0,0,0.6)"/>
        <text x="50%" y="17px" font-family="'Courier New', monospace" font-size="12px" fill="white">
            <tspan fill="rgba(255,255,255,0.6)">
                Arbitrator
            </tspan>
        </text>
        <text x="50%" y="30px" font-family="'Courier New', monospace" font-size="9px" fill="white">
            ${partyArbitrator}
        </text>
        <text x="50%" y="45px" font-family="'Courier New', monospace" font-size="12px" fill="white">
            Fee ${arbitratorFee}%
        </text>
    </g>

    <g style="transform:translate(0, 445px)" dominant-baseline="middle" text-anchor="middle">
        <rect x="20px" y="0px" width="${W - 40}px"  height="45px" fill="rgba(0,0,0,0.6)" rx="4px" />
        <text x="12px" y="17px" font-family="'Courier New', monospace" font-size="12px" fill="white">
            <tspan fill="rgba(255,255,255,0.6)" x="50%">
                Resolution Date
            </tspan>
            <tspan dy="1.2em" x="50%">
                ${resolveTime}
            </tspan>
        </text>
    </g>
    
    <text fill="white" x="50%" y="-4px" transform="rotate(90)" font-size="10px" font-family="'Courier New', monospace">
        ${tokenA.address} • ${tokenA.symbol} 
    </text>
    <text fill="white" x="50%" y="${-W + 10}px" 
          transform="rotate(90)" font-size="10px" font-family="'Courier New', monospace">
        ${tokenB.address} • ${tokenB.symbol} 
    </text>
    <rect x="7" y="5" width="${W-14}" height="${H-10}" 
          rx="10" ry="10" 
          fill="rgba(0,0,0,0)" stroke="rgba(255,255,255,0.2)"/>
</svg>
`;
}

export default generateNftSvg;
