import React, { useMemo, useState } from 'react';
import {
    Box,
    Flex,
    FormLabel,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea
} from '@chakra-ui/react';
import Input from '../Form/Input';
import { AtSignIcon } from '@chakra-ui/icons';
import { ReactComponent as TwitterIcon } from '../../assets/icons/logos/logo-twitter.svg';
import { useNetwork } from 'wagmi';
import ReactGA from 'react-ga4';

interface TweetModalProps {
    amount?: string;
    currencyName?: string;
    isOpen: boolean;
    onClose: () => void;
    tagId?: number;
}

const TweetModal: React.FC<TweetModalProps> = ({ isOpen, onClose, amount, currencyName, tagId }) => {
    const { chain } = useNetwork();
    const [counterP, setCounterP] = useState<string | null>(null);
    const [arb, setArb] = useState<string | null>(null);
    const [msg, setMsg] = useState<string | null>('Put your money where your mouth is');

    const tweetText = useMemo(() => {
        if (!chain) {
            return '';
        }
        let text = 'I just TAG\'d';
        const chainPostfix = chain.network === 'homestead' ? '' : `/${chain.network}`;
        const hasCounter = counterP && counterP !== '';
        text += !hasCounter ? ` for ${amount} ${currencyName} with @tagmiofficial.` : '';
        text += hasCounter ? `  @${counterP} with @tagmiofficial for ${amount} ${currencyName}.` : '';
        text += msg ? `%0A ${msg}` : '';

        text += arb && arb !== '' ? ` %0AOutcome will be determined by @${arb}.` : '';
        text += `%0A%0Ahttps://tagmi.app/tag/${tagId}${chainPostfix}`;
        return text;
    }, [chain, counterP, arb, amount, tagId, currencyName, msg]);

    const tweetDisplayText = useMemo(() => {
        if (!chain) {
            return '';
        }
        let displayText = 'I just TAG\'d';
        const chainPostfix = chain.network === 'homestead' ? '' : `/${chain.network}`;
        const hasCounter = counterP && counterP !== '';
        displayText += !hasCounter ? ` for ${amount} ${currencyName} with @tagmiofficial.` : '';
        displayText += hasCounter ? `  @${counterP} with @tagmiofficial for ${amount} ${currencyName}.` : '';
        displayText += msg ? `\n ${msg}` : '';
        displayText += arb && arb !== '' ? `\n Outcome will be determined by @${arb}.` : '';
        displayText += `\n https://tagmi.app/tag/${tagId}${chainPostfix}`;
        return displayText;
    }, [chain, counterP, arb, amount, tagId, currencyName, msg]);

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose} size='2xl' closeOnEsc closeOnOverlayClick={false}>
            <ModalOverlay bg='blackAlpha.500'/>
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>
                    Tweet your challenge
                </ModalHeader>
                <ModalBody>
                    <Flex direction='column' gap={2}>
                        <Input
                            placeholder={`counterparty`}
                            label={`Counterparty's twitter handle`}
                            leftElement={{
                                padding: '2px 5px',
                                mr: '50px',
                                height: '100%',
                                width: 'auto',
                                children: <AtSignIcon />
                            }}
                            onChange={(e: any) => setCounterP(e.target.value.replace(' ', '').replace('@', ''))} />
                        <Input
                            placeholder={`arbitrator`}
                            label={`Arbitrator's twitter handle`}
                            leftElement={{
                                padding: '2px 5px',
                                mr: '50px',
                                height: '100%',
                                width: 'auto',
                                children: <AtSignIcon />
                            }}
                            onChange={(e: any) => setArb(e.target.value.replace(' ', '').replace('@', ''))}/>
                        <Box>
                            <FormLabel
                                textAlign='left'
                                color='gray.500'
                                fontSize='14px'
                                fontWeight={500}
                                lineHeight={1.43}
                                mb='4px'>
                                Leave a message
                            </FormLabel>
                            <Textarea
                                placeholder={`Put your money where your mouth is`}
                                onChange={(e: any) => setMsg(e.target.value.replace('@', ''))}/>
                        </Box>

                        <Text size='xs' mt={5}>
                            sample tweet
                        </Text>
                        <Text
                            color='gray.500'
                            fontSize='sm'
                            w='100%'
                            p={3}
                            bg='gray.50'
                            sx={{
                                borderTop: '1px solid #cacaca',
                                borderBottom: '1px solid #cacaca',
                                whiteSpace: 'pre-line'
                            }}
                            textAlign='left'>
                            {tweetDisplayText}
                        </Text>
                        <Box m='10px auto'>
                            <a
                                className='twitter-share-button'
                                href={`https://twitter.com/intent/tweet?text=${tweetText}`}
                                target='_blank'
                                data-size='large'
                                onClick={() => {
                                    ReactGA.event({
                                        category: 'TWEET',
                                        action: 'Clicked on Tweet',
                                    });
                                }}>
                                <TwitterIcon />
                                tweet
                            </a>
                        </Box>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default TweetModal;
