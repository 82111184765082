import React from 'react';
import CenteredViewContainer from '../components/Containers/CenteredViewContainer';
import { Button, Center, Flex, Icon, Image, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MdArrowRightAlt } from 'react-icons/md';
import LogoType from '../assets/image/logotype.png';
import { AppRoute } from '../helpers/constants';

const NotFound: React.FC = () => {
    const navigate = useNavigate();
    return (
        <CenteredViewContainer>
            <Flex direction='column' gap={4} mt={10}>
                <Image src={LogoType} h='90px' mt='20px' mb={20} />
                <Center mb={5}>
                    <Text fontSize='30px' color='gray.500'>
                        Page not found.
                    </Text>
                </Center>
                <Button colorScheme='blue' onClick={() => navigate(AppRoute.TAG, { replace: true })}>
                    Go to homepage
                    <Icon as={MdArrowRightAlt} ml={4} />
                </Button>
            </Flex>
        </CenteredViewContainer>
    );
}

export default NotFound