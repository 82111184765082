import { Box, Button, Center, Flex, Hide, Image, Spacer, Text } from '@chakra-ui/react';
import Logotype from '../Logotype';
import Logo from '../../assets/image/logo.png';
import Products from '../Products';
import NetworkPicker from '../Buttons/NetworkPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import React, { useContext } from 'react';
import { Web3Context } from '../../providers/Web3Provider';
import UserDisplay from '../../views/UserDisplay';

const BaseHeader: React.FC<{ onOpen: () => void }> = ({ onOpen }) => {
    const { walletAddress, disconnectWallet, isConnected } = useContext(Web3Context);

    return (
        <>

            <Flex ml={10}>
                <Center>
                    { !isConnected && <Logotype /> }
                    { isConnected &&
                        <Hide below='md'>
                            <Image src={Logo} height='40px' mr={25} />
                        </Hide>
                    }
                </Center>
                <Center ml='10px'>
                    <Products />
                </Center>
            </Flex>

            <Spacer />

            {!isConnected &&
                <Button
                    borderRadius='10px'
                    colorScheme='blue'
                    mr={10}
                    p='16px'
                    onClick={onOpen}
                    size={{ sm: 'xs', md: 'md', lg: 'md', xl: 'md' }}>
                    Connect Wallet
                </Button>
            }
            { walletAddress &&
                <>
                    <NetworkPicker />
                    <Button size='lg' bg='white' userSelect='none' variant='outline' cursor='default'>
                        <Hide below='md'>
                            <Text fontWeight='bold' fontSize='14px'>
                                <UserDisplay address={walletAddress} format />
                            </Text>
                        </Hide>
                        <Box
                            borderRadius='45%'
                            bg='red.500'
                            color='white'
                            cursor='pointer'
                            ml={3}
                            onClick={disconnectWallet}
                            p='5px'>
                            <FontAwesomeIcon icon={faPowerOff} />
                        </Box>
                    </Button>
                </>
            }
        </>
    )
}

export default BaseHeader;
