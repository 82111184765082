import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    configureChains,
    Connector,
    createClient,
    useAccount,
    useConnect,
    useDisconnect,
    useNetwork,
    useSigner,
    useSwitchNetwork,
    WagmiConfig,
} from 'wagmi'
import { arbitrum, polygonMumbai } from 'wagmi/chains';
import { mainnet } from './mainnet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';
import { ethers } from 'ethers';
import TagEscrowAbi from '../assets/abis/TagEscrow.json';
import TagNftAbi from '../assets/abis/TagNFT.json';
import TagEscrowOtcAbi from '../assets/abis/TagEscrowOtc.json';
import { APP_NETWORK_CONFIG, INetworks } from '../helpers/appConfig';

export { type Connector };
type ConnectWallet = (connector: Connector) => Promise<{ address: string; }>;
type DisconnectWallet = () => Promise<void>;
type Signer = ReturnType<typeof useSigner>['data'];
export type SwitchNetwork = (networkId: number) => Promise<void>;

interface IContracts {
    escrow: ethers.Contract | null;
    nft: ethers.Contract | null;
    escrowOtc: ethers.Contract | null;
}

export interface IWeb3 {
    appConfig: any,
    connectError: Error | null;
    connectIsLoading: boolean;
    connector?: Connector;
    connectors: Connector[];
    connectWallet: ConnectWallet;
    disconnectWallet: DisconnectWallet;
    isConnected: boolean;
    pendingConnector: Connector<any, any> | undefined;
    signer: Signer;
    setAppConfig: Dispatch<SetStateAction<INetworks>>;
    switchNetwork: SwitchNetwork;
    walletAddress?: string;
    wrongChain: boolean;
    contracts: IContracts
}

const { chains, provider, webSocketProvider } = configureChains(
    [ mainnet, arbitrum, polygonMumbai ],
    [
        publicProvider({ weight: 2 }),
        infuraProvider({ infuraId: 'f2a78ac7d0e445d89c2af8cffbe58842', pollingInterval: 10_000, weight: 1 })
    ],
);

export const appChains = chains;

const client = createClient({
    autoConnect: true,
    connectors: [
        new MetaMaskConnector({ chains }),
        new WalletConnectConnector({
            chains,
            options: {
                infuraId: 'f2a78ac7d0e445d89c2af8cffbe58842',
                qrcode: true,
                qrcodeModalOptions: {
                    mobileLinks: [
                        'metamask',
                        'trust',
                        'coinbase',
                    ],
                },
            },
        }),
        new CoinbaseWalletConnector({
            chains,
            options: {
                appName: 'TAGMI',
                appLogoUrl: 'https://tagmi.app/favicon.ico'
            }
        })
    ],
    provider,
    webSocketProvider
});

export const Web3Context = createContext<IWeb3>({
    appConfig: APP_NETWORK_CONFIG,
    connectError: null,
    connectIsLoading: false,
    connector: undefined,
    connectors: [],
    connectWallet: async () => ({ address: '' }),
    disconnectWallet: async () => undefined,
    isConnected: false,
    pendingConnector: undefined,
    signer: undefined,
    switchNetwork: async () => undefined,
    setAppConfig: () => undefined,
    walletAddress: undefined,
    wrongChain: false,
    contracts: {
        escrow: null,
        nft: null,
        escrowOtc: null,
    }
});

const Provider: React.FC<{ children: any }> = ({ children }) => {
    const { address, connector, isConnected } = useAccount();
    const {
        connectAsync,
        connectors,
        error: connectError,
        isLoading: connectIsLoading,
        pendingConnector,
    } = useConnect();
    const { disconnectAsync } = useDisconnect();
    const { chain } = useNetwork();
    const { data: signer } = useSigner();
    const { switchNetworkAsync } = useSwitchNetwork();

    const [wrongChain, setWrongChain] = useState<boolean>(false);
    const [escrowContract, setEscrowContract] = useState<ethers.Contract | null>(null);
    const [nftContract, setNftContract] = useState<ethers.Contract | null>(null);
    const [escrowContractOtc, setEscrowContractOtc] = useState<ethers.Contract | null>(null);
    const [appConfig, setAppConfig] = useState(APP_NETWORK_CONFIG);

    const connectWallet: ConnectWallet = async connector => {
        const data = await connectAsync({ connector });
        return { address: data.account };
    }

    const disconnectWallet = async () => {
        await disconnectAsync();
    }
    const switchNetwork: SwitchNetwork = async (networkId: number) => {
        if (!switchNetworkAsync) {
            throw 'Something happened while trying to switch networks';
        }
        const network = networkId ? await switchNetworkAsync(networkId) : await switchNetworkAsync(80001);
        if (!network) {
            throw 'Something happened while trying to switch networks';
        }
    }

    useEffect(() => {
        if (!chain) {
            return;
        }
        const chainUnsupported = chain.unsupported ?? true;

        setWrongChain(chainUnsupported);
        if (chainUnsupported) {
            return;
        }

        const networkConfig = appConfig[chain.id];
        if (signer) {
            const escrow = new ethers.Contract(
                networkConfig.escrowContractAddress,
                TagEscrowAbi.abi,
                signer
            );
            const nft = new ethers.Contract(
                networkConfig.nftContractAddress,
                TagNftAbi,
                signer
            );
            const escrowOtc = new ethers.Contract(
                networkConfig.escrowOtcAddress,
                TagEscrowOtcAbi.abi,
                signer
            );
            setEscrowContract(escrow);
            setNftContract(nft);
            setEscrowContractOtc(escrowOtc);
        }
    }, [chain, signer, appConfig]);

    return (
        <Web3Context.Provider value={{
            appConfig,
            connectError,
            connectIsLoading,
            connector,
            connectors,
            connectWallet,
            disconnectWallet,
            isConnected,
            pendingConnector,
            signer,
            setAppConfig,
            switchNetwork,
            walletAddress: address,
            wrongChain,
            contracts: {
                escrow: escrowContract,
                nft: nftContract,
                escrowOtc: escrowContractOtc,
            }
        }}>
            {children}
        </Web3Context.Provider>
    );
};

export const Web3Provider: React.FC<{ children: any }> = ({ children }) => {
    return (
        <WagmiConfig client={client}>
            <Provider>
                {children}
            </Provider>
        </WagmiConfig>
    );
};