import { Chain } from 'wagmi';

export const mainnet = {
    'id': 1,
    'name': 'Ethereum',
    'network': 'homestead',
    'nativeCurrency': {
        'name': 'Ether',
        'symbol': 'ETH',
        'decimals': 18
    },
    'rpcUrls': {
        'alchemy': 'https://eth-mainnet.g.alchemy.com/v2',
        'default': 'https://cloudflare-eth.com',
        'infura': 'https://mainnet.infura.io/v3',
        'public': 'https://cloudflare-eth.com'
    },
    'blockExplorers': {
        'etherscan': {
            'name': 'Etherscan',
            'url': 'https://etherscan.io'
        },
        'default': {
            'name': 'Etherscan',
            'url': 'https://etherscan.io'
        }
    },
    'ens': {
        'address': '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
    },
    'multicall': {
        'address': '0xca11bde05977b3631167028862be2a173976ca11',
        'blockCreated': 14353601
    }
} as const satisfies Chain;