import React from 'react';
import { Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { IOTCEscrow } from '../../../helpers/constants';
import OtcListEntry from './OtcListEntry';

type OtcListProps = {
    escrowsOtc: IOTCEscrow[],
    category: string;
}

const OtcList: React.FC<OtcListProps> = ({ escrowsOtc, category }) => {
    return (
        <Flex alignItems='center' direction='column' w='100%'>
            {escrowsOtc.length === 0 &&
                <Text w='100%' color='gray.400' textAlign='center' pt='30px' userSelect='none'>
                    Nothing here. Create a Swap.
                </Text>
            }
            <SimpleGrid columns={[2, null, 3]} spacing={1} w='100%'>
                {escrowsOtc.map((escrow: IOTCEscrow) => (
                    <OtcListEntry otcEscrow={escrow} category={category} key={`${escrow.escrowId}-otc`}/>
                ))}
            </SimpleGrid>
        </Flex>
    );
};

export default OtcList;