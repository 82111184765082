import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Web3Context } from '../../../providers/Web3Provider';
import {
    Box,
    Button,
    Center,
    Flex,
    IconButton,
    Link,
    SimpleGrid,
    Spacer,
    Stack,
    Text,
    useColorModeValue,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
import moment from 'moment/moment';
import { ethers } from 'ethers';
import { erc20ABI, useNetwork } from 'wagmi';
import { uploadNftDataToIpfs } from '../../../helpers/uploadMetada';
import generateNftSvg from '../../../helpers/generateNftSvg';
import { AppRoute, IDepositAsset, IEscrow, IFormattedAsset, ZERO_ADDY } from '../../../helpers/constants';
import getCurrencyDecimalsAndSymbol from '../../../helpers/getCurrencyDecimalsAndSymbol';
import TransferModal from '../../../components/Modals/TransferModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoMdSend } from 'react-icons/io';
import getTagOwnerAddress from '../../../helpers/getTagOwnerAddress';
import formatDt from '../../../helpers/format/formatDt';
import { TagViewCategories } from '../index';
import UserDisplay from '../../UserDisplay';

interface IEscrowEntryProps {
    chainEscrow: IEscrow;
    category: string;
}

const EscrowListEntry: React.FC<IEscrowEntryProps> = ({ chainEscrow, category }) => {
    const { chain } = useNetwork()
    const toast = useToast();
    const navigate = useNavigate();
    const { search } = useLocation();

    const { walletAddress, signer, contracts: { escrow, nft } } = useContext(Web3Context);
    const { isOpen, onClose, onOpen } = useDisclosure();

    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const [forDeposit, setForDeposit] = useState<string>('');
    const [againstDeposit, setAgainstDeposit] = useState<string>('');
    const [ownerPro, setOwnerPro] = useState<string | undefined>(undefined);
    const [ownerAgainst, setOwnerAgainst] = useState<string | undefined>(undefined);
    const isArbitrator = walletAddress === chainEscrow.partyArbitrator;

    useEffect(() => {
        setParties();
    }, [chainEscrow, escrow, nft]);

    const setParties = async () => {
        if (!escrow || !nft) {
            return;
        }
        setOwnerPro(await getTagOwnerAddress(chainEscrow, nft));
        setOwnerAgainst(await getTagOwnerAddress(chainEscrow, nft, false));
    }

    const depositToEscrow = async (tagEscrow: IEscrow) => {
        if (!signer || !escrow) {
            return;
        }
        setBtnLoading(true);
        const currencyAddress = tagEscrow.pendingAssetB.currency;
        const currencyAmount = tagEscrow.pendingAssetB.amount;
        const payingWithGas = currencyAddress === ZERO_ADDY;

        const token = new ethers.Contract(currencyAddress, erc20ABI, signer);
        if (!payingWithGas) {
            const allowance = await token.allowance(walletAddress, escrow.address);
            if (allowance < currencyAmount) {
                await (
                    await token.approve(escrow.address, currencyAmount)
                ).wait();
            }
        }
        try {
            const escrowId = tagEscrow.escrowId.toNumber();
            const nftAIpfsUrl = await generateNft(escrowId, true);
            const nftBIpfsUrl = await generateNft(escrowId, false);
            const paymentParams = { value: !payingWithGas ? 0 : currencyAmount };
            const tx = await escrow.depositFunds(escrowId, nftAIpfsUrl, nftBIpfsUrl, { ...paymentParams });
            await tx.wait();
            setBtnLoading(false);
            window.location.reload();
        } catch (e: any) {
            console.log(e)
            toast({
                title: e?.reason ?? e?.data?.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setBtnLoading(false);
        }
    };

    const withdrawFunds = async (escrowId: number, escrowContract: ethers.Contract) => {
        if (!signer || !escrow) {
            return;
        }
        setBtnLoading(true);
        const tx = await escrowContract.withdrawFunds(escrowId);
        await tx.wait();
        window.location.reload();
        setBtnLoading(false);
    };

    const reclaimFunds = async (escrowId: number, escrowContract: ethers.Contract) => {
        if (!signer || !escrow) {
            return;
        }
        setBtnLoading(true);
        const tx = await escrowContract.reclaimFunds(escrowId);
        await tx.wait();
        setBtnLoading(false);
        window.location.reload();
    };

    const generateNft = async (escrowId: number, forPartyA: boolean): Promise<string> => {
        if (!signer || !escrow) {
            throw new Error('Params not set yet');
        }
        const assetsA = await escrow.escrowAssetsA(escrowId);
        const assetsB = chainEscrow.pendingAssetB;
        const svg = generateNftSvg(
            chainEscrow,
            await getFormattedToken(assetsA),
            await getFormattedToken(assetsB),
            forPartyA
        );
        const blob = new Blob([svg], { type: 'image/svg+xml' });
        return await uploadNftDataToIpfs(
            chainEscrow,
            forPartyA,
            assetsA.currency,
            assetsB.currency,
            blob
        );
    }

    useEffect(() => {
        loadEscrowDetails();
    }, [walletAddress, escrow, signer, chainEscrow]);

    const isProSide = useMemo(() => walletAddress === ownerPro, [ownerPro]);

    const loadEscrowDetails = async () => {
        if (!escrow || !signer || !chain) {
            return;
        }
        const escrowId = chainEscrow.escrowId;
        const assetsA = await escrow.escrowAssetsA(escrowId);
        const assetsB = chainEscrow.pendingAssetB;
        const currencyA = new ethers.Contract(assetsA.currency, erc20ABI, signer);
        const currencyB = new ethers.Contract(assetsB.currency, erc20ABI, signer);
        const { decimals: decimalsA, symbol: symbolA } = await getCurrencyDecimalsAndSymbol(currencyA, chain);
        const { decimals: decimalsB, symbol: symbolB } = await getCurrencyDecimalsAndSymbol(currencyB, chain);
        const amountA = ethers.utils.formatUnits(`${assetsA.amount}`, decimalsA);
        const amountB = ethers.utils.formatUnits(`${assetsB.amount}`, decimalsB);
        setForDeposit(amountA + '\n' + symbolA);
        setAgainstDeposit(amountB + '\n' + symbolB)
    }

    const getFormattedToken = async (asset: IDepositAsset): Promise<IFormattedAsset> => {
        if (!escrow || !signer || !chain) {
            throw new Error('Signer not connected');
        }
        const tokenContract = new ethers.Contract(asset.currency, erc20ABI, signer);
        const { decimals, symbol } = await getCurrencyDecimalsAndSymbol(tokenContract, chain);
        return {
            symbol,
            address: tokenContract.address,
            decimals,
            amount: asset.amount
        };
    }

    const wrapLoading = async (method: () => void): Promise<void> => {
        setBtnLoading(true);
        try {
            await method();
        } catch (e: any) {
            console.log(e, typeof e)
            toast({
                title: e?.reason,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setBtnLoading(false);
        }
    }

    const btnProps = {
        borderRadius: 5,
        bg: 'button.action',
        color: 'white',
        mt: 10,
        w: 'full',
        _hover: { bg: 'tagmi.logo' },
        _focus: { bg: 'tagmi.logo' },
    };

    const getButton = () => {
        if (!escrow || !nft) {
            return;
        }
        const determineUnix = moment.unix(chainEscrow.determineTime.toNumber());
        const depositPending1Day = moment().isAfter(
            moment.unix(chainEscrow.createTime.toNumber()).add(24, 'h')
        );
        const undeterminedPast3Days = moment().isAfter(determineUnix.add(72, 'h'));
        const escrowId = chainEscrow.escrowId.toNumber();
        const canDeposit = category === TagViewCategories.PENDING && !isProSide && !isArbitrator;
        const canWithdraw = category === TagViewCategories.PENDING && depositPending1Day && isProSide && !isArbitrator;
        const canReclaim = category === TagViewCategories.OPEN && undeterminedPast3Days && !isArbitrator;
        return (
            <>
                {canDeposit && (
                    <Button
                        {...btnProps}
                        isLoading={btnLoading}
                        onClick={() => depositToEscrow(chainEscrow)}>
                        Approve and Deposit
                    </Button>
                )}
                {canWithdraw && (
                    <Button
                        {...btnProps}
                        isLoading={btnLoading}
                        onClick={() => wrapLoading(() => withdrawFunds(escrowId, escrow))}>
                        Withdraw funds
                    </Button>
                )}
                {canReclaim && (
                    <Button
                        {...btnProps}
                        isLoading={btnLoading}
                        onClick={() => wrapLoading(() => reclaimFunds(escrowId, escrow))}>
                        Reclaim funds
                    </Button>
                )}
            </>
        )
    }

    return (
        <Flex py={4} borderRadius='xl' w='300px' h='800px'>
            <Box maxW='330px' w='full' bg='stefanos.0' boxShadow='2xl' rounded='xl' overflow='hidden'>
                <Stack
                    textAlign='center'
                    p={3}
                    color={useColorModeValue('gray.800', 'white')}
                    direction='column'
                    spacing={2}
                    height='3xs'>
                    <Flex>
                        <Flex>
                            <Link
                                alignItems='top'
                                color='gray.800'
                                fontWeight='bold'
                                fontSize='2xl'
                                py={1}
                                px={2}
                                onClick={() => {
                                    const id = chainEscrow.escrowId.toNumber();
                                    const chainName = chain?.network === 'homestead' ? '' : `/${chain?.network}`;
                                    navigate(`${AppRoute.TAG}tag/${id}${chainName}${search}`);
                                }}>
                                TAG#{ chainEscrow.escrowId.toNumber() }
                            </Link>
                        </Flex>
                        <Spacer />
                        <Flex>
                            {category === TagViewCategories.OPEN && (
                                <IconButton
                                    aria-label='Transfer claim'
                                    colorScheme='teal'
                                    fontSize='20px'
                                    size='md'
                                    variant='solid'
                                    onClick={onOpen}
                                    isLoading={btnLoading}
                                    icon={<IoMdSend />}/>
                            )}
                        </Flex>
                    </Flex>
                    <Text fontSize='md' alignItems='top' color={ isProSide ? 'green' : 'red' }>
                        { isProSide ? 'FOR' : 'AGAINST' }
                    </Text>
                    <Text fontSize='md' textAlign='left' textColor='gray.700'>
                        { chainEscrow.description }
                    </Text>
                </Stack>
                <Stack direction='column' spacing={1} bg='#C2EEF1' alignItems='center'>
                    <Text fontSize='lg' color='gray.500'>
                        Arbitrator
                    </Text>
                    <Text fontWeight='bold' fontSize='2xs' color='gray.800'>
                        {chainEscrow.partyArbitrator === walletAddress
                            ? 'You'
                            : <UserDisplay address={chainEscrow.partyArbitrator} /> }
                    </Text>
                    <Text fontWeight='bold' fontSize='sm' color='gray.400'>
                        {chainEscrow.arbitratorFeeBps.toNumber()/100}% fee
                    </Text>
                </Stack>

                {chainEscrow.winner !== ZERO_ADDY && (
                    <Center bg='teal.100' fontSize='lg' fontWeight='bold'>
                        {chainEscrow.winner === ownerPro
                            ? 'FOR WON'
                            : 'AGAINST WON'
                        }
                    </Center>)}
                <Box bg='stefanos.0' px={6} py={10}>
                    <SimpleGrid columns={2} spacing={4}>
                        <Text>Counterparty</Text>
                        <Text color='gray.800' fontWeight='bold'>
                            {isProSide ? ownerAgainst : ownerPro}
                        </Text>

                        <Text>
                            FOR
                        </Text>
                        <Text color='gray.800' fontWeight='bold' whiteSpace={'pre'}>
                            {forDeposit}
                        </Text>

                        <Text>
                            AGAINST
                        </Text>
                        <Text color='gray.800' fontWeight='bold' whiteSpace={'pre'}>
                            {againstDeposit}
                        </Text>

                        <Text>Resolution date</Text>
                        <Text color='purple.400' fontWeight='bold' fontSize='md'>
                            {formatDt(moment.unix(chainEscrow.determineTime.toNumber()))}
                        </Text>

                        <Text>Winner</Text>
                        <Text
                            color={ chainEscrow.winner !== ZERO_ADDY ? 'purple.400' : 'gray.400' }
                            fontWeight='bold'
                            fontSize='xxs'>
                            { chainEscrow.winner !== ZERO_ADDY ? chainEscrow.winner : 'No winner yet' }
                        </Text>
                    </SimpleGrid>
                    <Flex>
                        { getButton() }
                    </Flex>
                    {
                        category === TagViewCategories.PENDING && !isProSide && !isArbitrator &&
                        <Flex  w='100%' textAlign='center'>
                            <Text size='sm' color='rose.400' w='100%' >
                                deposit {againstDeposit}
                            </Text>
                        </Flex>
                    }
                </Box>
            </Box>
            <TransferModal isOpen={isOpen} onClose={onClose} chainEscrow={chainEscrow} />
        </Flex>
    );
}

export default EscrowListEntry;
