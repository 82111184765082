import React from 'react';
import { useEnsName } from 'wagmi';
import { Text } from '@chakra-ui/react';
import { ethers } from 'ethers';
import formatWalletAddress from '../helpers/format/formatWalletAddress';

const UserDisplay: React.FC<{ address: string, format?: boolean; }> = ({ address, format = false }) => {
    const { data: ensName } = useEnsName({
        address: address,
        chainId: 1,
    });

    const nAddress = address === ethers.constants.AddressZero ? '-' : format ? formatWalletAddress(address) : address;

    return (
        <Text>
            { ensName ?? nAddress }
        </Text>
    )
}

export default UserDisplay;
