import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    Text,
    Box,
    Divider,
    Flex,
    Image,
    List,
    ListItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay, Spacer, Spinner, useToast,
} from '@chakra-ui/react';
import { Web3Context } from '../../providers/Web3Provider';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ethers } from 'ethers';
import {erc20ABI, useNetwork} from 'wagmi';
import { useDebounce } from '../../hooks/useDebounce';
import Input from '../Form/Input';
import { ISelectToken } from '../../views/Tag/CreateEscrow';
import { CheckIcon } from '@chakra-ui/icons';

interface CurrencyModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSelectCurrency: (currency: ISelectToken) => void;
    selectedCurrency: ISelectToken | null;
}

const CurrencyModal: React.FC<CurrencyModalProps> = ({ isOpen, onClose, onSelectCurrency, selectedCurrency }) => {
    const { appConfig, signer, wrongChain, contracts: { escrow } } = useContext(Web3Context);
    const { chain } = useNetwork();
    const toast = useToast();

    const [searchText, setSelectedSearchText] = useState<string>('');
    const [defaultTokens, setDefaultTokens] = useState<ISelectToken[]>([])
    const [tokens, setTokens] = useState<ISelectToken[]>([]);
    const [loading, setLoading] = useState(false);

    const debouncedSearchTerm = useDebounce(searchText, 300);

    useEffect(() => {
        onInputChange()
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (!chain) {
            return;
        }
        setDefaultTokens(appConfig[chain.id]?.defaultCoins ?? [])
    }, [chain]);

    useEffect(() => {
        if (wrongChain || !chain) {
            return;
        }
        setTokens(appConfig[chain.id]?.defaultCoins ?? []);
    }, [wrongChain, chain, appConfig]);

    const onInputChange = useCallback(async () => {
        if (!escrow || !signer) {
            return;
        }
        try {
            const isAddress = ethers.utils.isAddress(searchText);
            if (isAddress) {
                setLoading(true);
                const tokenC = new ethers.Contract(searchText, erc20ABI, signer);
                setTokens([
                    {
                        symbol: await tokenC.symbol(),
                        name: await tokenC.name(),
                        address: await tokenC.address,
                        decimals: await tokenC.decimals(),
                        logoURI: process.env.PUBLIC_URL + '/qm.svg',
                    }
                ]);
                setLoading(false);
            } else {
                const found = defaultTokens.filter(token => {
                    return (
                        token.name.startsWith(searchText) ||
                        token.symbol.startsWith(searchText) ||
                        token.address === searchText
                    );
                });
                setTokens(found);
            }
        } catch (e) {
            console.log('error', e)
            setLoading(false);
            onClose();
            toast({
                title: 'This does not seem like a valid token address',
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            return;
        }
    }, [searchText]);

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose} size='lg' closeOnEsc>
            <ModalOverlay bg='blackAlpha.500'/>
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>
                    Select a token
                </ModalHeader>
                <ModalBody p={0}>
                    <Box mb={2} p={3}>
                        <Input
                            autoComplete='chrome-off'
                            type='text'
                            placeholder='Search name or paste address'
                            onChange={e => setSelectedSearchText(e.target.value)}
                            rightElement={{
                                mr: '20px',
                                height: '100%',
                                width: 'auto',
                                children: <SearchIcon stroke='gray' />
                            }}
                            _active={{ boxShadow: 'none' }}
                            _focus={{ boxShadow: 'none' }} />
                    </Box>
                    <Divider colorScheme={'red'}/>
                    <List h='320px' mt={2}>
                        { loading &&
                            <Box w='100%' textAlign='center'>
                                <Spinner label='Searching' />
                            </Box>
                        }
                        { !loading && tokens.length === 0 &&
                            <Text color='gray.400' w='100%' textAlign='center'>
                                No token found.
                            </Text>
                        }
                        { !loading && tokens.map((token: ISelectToken) => {
                            const isSelected = selectedCurrency?.address === token.address;
                            return (
                                <ListItem
                                    bgColor={isSelected ? 'gray.100' : 'white'}
                                    cursor={isSelected ? 'default' : 'pointer'}
                                    key={token.address}
                                    mt={1}
                                    onClick={() => onSelectCurrency(token)}
                                    sx={{ filter: `brightness(${!isSelected ? '100%' : '90%'})` }}
                                    _hover={{ bg: isSelected ? '' : 'gray.100' }}>
                                    <Flex p='4px 10px' w='100%'>
                                        <Flex w='45px'>
                                            <Image src={token.logoURI} borderRadius='50%' height='36px'/>
                                        </Flex>
                                        <Flex direction='column'>
                                            <Flex fontWeight='bold'>
                                                {token.name}
                                            </Flex>
                                            <Flex fontSize='sm' color='gray.500'>
                                                {token.symbol}
                                            </Flex>
                                        </Flex>
                                        <Spacer />
                                        <Flex alignItems='center'>
                                            { isSelected && <CheckIcon color='green.500' boxSize={5}/>}
                                        </Flex>
                                    </Flex>
                                </ListItem>)
                        }
                        )}
                    </List>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default CurrencyModal;
