import { ethers } from 'ethers';

const determineOutcome = async (escrowId: number, partyAWon: boolean, escrowContract: ethers.Contract) => {
    const tx = await escrowContract.determineOutcome(escrowId, partyAWon);
    await tx.wait();
    window.location.reload();
};

const withdrawFunds = async (escrowId: number, escrowContract: ethers.Contract) => {
    const tx = await escrowContract.withdrawFunds(escrowId);
    await tx.wait();
    window.location.reload();
};

const reclaimFunds = async (escrowId: number, escrowContract: ethers.Contract) => {
    const tx = await escrowContract.reclaimFunds(escrowId);
    await tx.wait();
    window.location.reload();
};

export {
    determineOutcome,
    withdrawFunds,
    reclaimFunds
};
