import { Box, Container } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import Header from '../Header';
import { useLocation } from 'react-router-dom';
import { Web3Context } from '../../providers/Web3Provider';
import { APP_NETWORK_CONFIG, APP_NETWORK_CONFIG_v1, APP_NETWORK_CONFIG_v2 } from '../../helpers/appConfig';

const ViewContainer: React.FC<{ children: any }> = props => {
    const { search } = useLocation();
    const { setAppConfig } = useContext(Web3Context);

    useEffect(() => {
        const searchParams = new URLSearchParams(search.substring(search.indexOf('?')));
        const version = searchParams.get('version') ? searchParams.get('version') : '3'
        if (version === '1') {
            setAppConfig(APP_NETWORK_CONFIG_v1)
        } else if (version === '2') {
            setAppConfig(APP_NETWORK_CONFIG_v2)
        } else {
            setAppConfig(APP_NETWORK_CONFIG)
        }
    }, [search]);

    return (
        <Container maxW='1920px' minH='100vh' px={0}>
            <Header />
            <Box px={{ base: '20px', md: '32px', lg: '72px' }} w='100%'>
                { props.children }
            </Box>
        </Container>
    );
}

export default ViewContainer