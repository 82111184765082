import { extendTheme } from '@chakra-ui/react';

import colors from './colors';
import fonts from './fonts';
import shadows from './shadows';
import styles from './styles';
import zIndices from './zIndices';

import Button from './components/Button';
import Heading from './components/Heading';
import Text from './components/Text';

export default extendTheme({
    colors,
    components: {
        Button,
        Heading,
        Text,
    },
    fonts,
    shadows,
    styles,
    zIndices,
});
