import React, { useContext, useState } from 'react';
import {
    Box,
    Button,
    Flex,
    InputGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useToast
} from '@chakra-ui/react';
import Input from '../Form/Input';
import { ethers } from 'ethers';
import { Web3Context } from '../../providers/Web3Provider';
import { IEscrow } from '../../helpers/constants';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod/dist/zod';

interface TransferModalProps {
    chainEscrow: IEscrow;
    isOpen: boolean;
    onClose: () => void;
}

const schema = z.object({
    walletAddress: z
        .string()
        .refine(address => ethers.utils.isAddress(address), {
            message: 'Wallet address is invalid',
        }),
});

type Schema = z.infer<typeof schema>;

const TransferModal: React.FC<TransferModalProps> = ({ isOpen, onClose, chainEscrow }) => {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const { walletAddress, signer, contracts: { escrow, nft } } = useContext(Web3Context);
    const { control, setValue, handleSubmit, watch } = useForm<Schema>({
        mode: 'onSubmit',
        resolver: zodResolver(schema),
    });
    const toast = useToast();
    const newOwner = watch('walletAddress');

    const transferNft = async () => {
        if (!signer || !escrow || !newOwner || !nft) {
            return;
        }
        if (newOwner === chainEscrow.partyArbitrator) {
            throw 'The Arbitrator cannot be the recipient of the TAG';
        }
        const nftTokenId = chainEscrow.partyA === walletAddress
            ? chainEscrow.nftA.toNumber()
            : chainEscrow.nftB.toNumber()
        const tx = await nft['safeTransferFrom(address,address,uint256)'](walletAddress, newOwner, nftTokenId);
        await tx.wait();
        return;
    };

    const onCloseModal = () => {
        setValue('walletAddress', '');
        onClose();
    }

    const onSubmit = async () => {
        try {
            setIsProcessing(true);
            await transferNft()
            setIsProcessing(false);
            window.location.reload();
            toast({
                title: 'Successfully transferred!',
                description: `Transferred wager to ${newOwner}`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        } catch (e: any) {
            toast({
                title: 'Transfer Failed',
                description: e?.reason ?? e,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        } finally {
            setIsProcessing(false);
            onClose();
        }
    }

    const onPaste = async () => {
        try {
            const text = await navigator.clipboard.readText();
            if (ethers.utils.isAddress(text)) {
                setValue('walletAddress', text);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Modal isCentered isOpen={isOpen} onClose={onCloseModal} size='lg' closeOnEsc>
            <ModalOverlay bg='blackAlpha.500'/>
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>
                    Transfer your NFT to another address
                </ModalHeader>
                <ModalBody>
                    <Box as='form' onSubmit={handleSubmit(onSubmit)}>
                        <Flex direction='column' gap={2}>
                            <Controller
                                control={control}
                                name='walletAddress'
                                render={({
                                    field: { name, onBlur, onChange, value },
                                    fieldState: { error }
                                }) => (
                                    <InputGroup maxW='500px' m='auto'>
                                        <Input
                                            pr='5rem'
                                            error={error?.message}
                                            name={name}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            placeholder='Wallet Address'
                                            rightElement={{
                                                mr: '0',
                                                height: '100%',
                                                width: 'auto',
                                                children: (
                                                    <Button
                                                        borderRadius={0}
                                                        borderRightRadius={10}
                                                        h='100%'
                                                        size='full'
                                                        onClick={onPaste}
                                                        colorScheme='teal'>
                                                        Paste
                                                    </Button>
                                                )
                                            }}
                                            type='text'
                                            value={value ?? undefined} />
                                    </InputGroup>
                                )} />
                            <Button
                                bg='#25273D'
                                color='white'
                                borderRadius={'md'}
                                size='md'
                                type='submit'
                                isLoading={isProcessing}>
                                Transfer
                            </Button>
                        </Flex>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default TransferModal;
