import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const ProductDescription: React.FC = () => (
    <Flex w='100%' alignItems='center' mt={25} mb={10} gap='2' direction='column' overflowWrap='break-word'>
        <Text fontSize='40px' color='gray.900'>
            Bet any token on any event
        </Text>
        <Box fontSize='18px' color='gray.400' textAlign='center'>
            <Text>Tokenize and trade wager positions. Earn fees as an Arbitrator.</Text>
        </Box>
    </Flex>
);

export default ProductDescription;
