import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Web3Context } from '../../providers/Web3Provider';
import { Button, ButtonGroup, Center, Hide, Spinner, Text } from '@chakra-ui/react';
import ViewContainer from '../../components/Containers/ViewContainer';
import ProductDescription from './ProductDescription';
import { IEscrow } from '../../helpers/constants';
import EscrowsList from './EscrowsList/EscrowsList';
import { useLocation } from 'react-router-dom';
import { BigNumber, ethers } from 'ethers';
import ReactGA from 'react-ga4';
import CreateEscrow from './CreateEscrow';
import Arbitrate from './Arbitrate';

export enum TagViewCategories {
    CREATE = 'Create',
    PENDING = 'Pending',
    OPEN = 'Open',
    DONE = 'Done',
    ARB = 'Arbitrate'
}

function Main() {
    const {
        appConfig,
        walletAddress,
        isConnected,
        signer,
        contracts: { escrow, nft },
        wrongChain
    } = useContext(Web3Context);
    const { pathname, search } = useLocation(); // TODO you can use this to swap version config on Header.ts
    const [escrows, setEscrows] = useState<IEscrow[]>([]);
    const [loadingE, setLoadingE] = useState<boolean>(false);
    const [activeCategory, setActiveCategory] = useState<TagViewCategories>(TagViewCategories.CREATE);

    useEffect(() => {
        if (!isConnected) {
            setActiveCategory(TagViewCategories.CREATE);
        }
    }, [isConnected])

    useEffect(() => {
        const page = pathname + search;
        ReactGA.send({ hitType: 'pageview', page });
    }, [pathname, search]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const loadEscrows = useCallback(async (address: string): Promise<void> => {
        if (!signer || !escrow || wrongChain || !nft || !walletAddress) {
            return;
        }
        setLoadingE(true);
        const scrsPromises: Promise<any>[] = [];
        const nftIds = await nft.getTokenIds(walletAddress);
        const startedEscrowIds = await Promise.all(
            nftIds.map(async (id: BigNumber) => {
                const nftEscrowDeets = await nft.nftEscrowDetails(id.toNumber())
                return nftEscrowDeets.escrowId;
            })
        );
        const pendingEscrowIds: BigNumber[] = await escrow.getEscrowsPendingDepositForAddress(address);
        const escrowIds = new Set();
        startedEscrowIds.map(id => escrowIds.add(id.toNumber()));
        pendingEscrowIds.forEach(id => escrowIds.add(id.toNumber()));
        escrowIds.forEach(id => scrsPromises.push(escrow.escrows(id)));

        const walletEscrows = await Promise.all(scrsPromises);
        const finalEscrows = walletEscrows.filter(sc => sc.partyA !== ethers.constants.AddressZero);
        setEscrows(finalEscrows);
        setLoadingE(false);
    }, [escrow, nft, signer, walletAddress, wrongChain]);

    useEffect(() => {
        if (walletAddress) {
            loadEscrows(walletAddress);
        }
    }, [appConfig, walletAddress, loadEscrows, wrongChain]);

    const pendingEscrows = useMemo(() => escrows.filter(escrow =>
        !escrow.started
        && !escrow.closed
        && escrow.partyArbitrator !== walletAddress
    ), [escrows]);
    const openEscrows = useMemo(() => escrows.filter(escrow =>
        escrow.started
        && !escrow.closed
        && escrow.partyArbitrator !== walletAddress
    ), [escrows]);
    const closedEscrows = useMemo(() => escrows.filter(escrow =>
        escrow.started && escrow.closed
    ), [escrows]);

    const displayEscrows = useMemo(() => {
        if (activeCategory === TagViewCategories.PENDING) return pendingEscrows;
        if (activeCategory === TagViewCategories.OPEN) return openEscrows;
        if (activeCategory === TagViewCategories.DONE) return closedEscrows;
        return null;
    }, [openEscrows, closedEscrows, pendingEscrows, activeCategory])

    return (
        <ViewContainer>
            { !isConnected && <ProductDescription /> }
            <Center mb={4}>
                <ButtonGroup borderRadius={10} boxShadow='0 0px 3px rgba(0, 0, 0, .3)'>
                    <Button
                        borderRadius={0}
                        borderLeftRadius={10}
                        colorScheme='pink'
                        variant={activeCategory === TagViewCategories.CREATE ? 'solid' : 'ghost'}
                        onClick={() => setActiveCategory(TagViewCategories.CREATE)}>
                        { TagViewCategories.CREATE }
                    </Button>
                    <Button
                        borderRadius={0}
                        colorScheme={activeCategory === TagViewCategories.PENDING ? 'teal' : 'gray'}
                        isDisabled={!isConnected}
                        onClick={() => setActiveCategory(TagViewCategories.PENDING)}>
                        { TagViewCategories.PENDING }
                        <Text size='xs' color={activeCategory === TagViewCategories.PENDING ? 'gray.200' : 'gray.500'}>
                            { loadingE && <Spinner size='xs' /> }
                            { !loadingE && <>{'  '}[{pendingEscrows.length}]</> }
                        </Text>
                    </Button>
                    <Button
                        colorScheme={activeCategory === TagViewCategories.OPEN ? 'teal' : 'gray'}
                        borderRadius={0}
                        isDisabled={!isConnected}
                        onClick={() => setActiveCategory(TagViewCategories.OPEN)}>
                        { TagViewCategories.OPEN }
                        <Text size='xs' color={activeCategory === TagViewCategories.OPEN ? 'gray.200' : 'gray.500'}>
                            { loadingE && <Spinner size='xs' /> }
                            { !loadingE && <>{'  '}[{openEscrows.length}]</> }
                        </Text>
                    </Button>
                    <Hide below='sm'>
                        <Button
                            colorScheme={activeCategory === TagViewCategories.DONE ? 'teal' : 'gray'}
                            borderRadius={0}
                            isDisabled={!isConnected}
                            onClick={() => setActiveCategory(TagViewCategories.DONE)}>
                            { TagViewCategories.DONE }
                            <Text size='xs' color={activeCategory === TagViewCategories.DONE ? 'gray.200' : 'gray.500'}>
                                { loadingE && <Spinner size='xs' /> }
                                { !loadingE && <>{'  '}[{closedEscrows.length}]</> }
                            </Text>
                        </Button>
                    </Hide>

                    <Button
                        colorScheme='blue'
                        borderRadius={0}
                        borderRightRadius={10}
                        isDisabled={!isConnected}
                        variant={activeCategory === TagViewCategories.ARB ? 'solid' : 'ghost'}
                        onClick={() => setActiveCategory(TagViewCategories.ARB)}>
                        { TagViewCategories.ARB }
                    </Button>
                </ButtonGroup>
            </Center>

            { isConnected && displayEscrows && <EscrowsList escrows={displayEscrows} category={activeCategory} /> }
            { activeCategory === TagViewCategories.CREATE &&  <CreateEscrow /> }
            { activeCategory === TagViewCategories.ARB &&  <Arbitrate /> }
        </ViewContainer>
    );
}

export default Main;
