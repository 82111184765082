import React from 'react';
import { Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import ConnectWallet from '../ConnectWallet';

interface WalletConnectModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const WalletConnectModal: React.FC<WalletConnectModalProps> = ({ isOpen, onClose }) => (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size='lg' closeOnEsc>
        <ModalOverlay bg='blackAlpha.500'/>
        <ModalContent>
            <ModalCloseButton />
            <ModalHeader textAlign='center'>
                Connect Wallet
            </ModalHeader>
            <ModalBody>
                <Flex alignItems='center' direction='column' gap='1' p='8px'>
                    <ConnectWallet onClose={onClose} />
                </Flex>
            </ModalBody>
        </ModalContent>
    </Modal>
);

export default WalletConnectModal;
