import {
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    Input as ChakraInput,
    InputAddonProps,
    InputElementProps,
    InputGroup,
    InputLeftAddon,
    InputLeftElement,
    InputProps as ChakraInputProps,
    InputRightAddon,
    InputRightElement,
    ResponsiveValue,
    useTheme,
} from '@chakra-ui/react';
import React from 'react';
import * as CSS from 'csstype';

export interface InputProps extends ChakraInputProps {
    error?: string;
    helperText?: string;
    label?: string;
    labelAlign?: ResponsiveValue<CSS.Property.TextAlign>;
    leftAddon?: InputAddonProps;
    leftElement?: InputElementProps;
    rightAddon?: InputAddonProps;
    rightElement?: InputElementProps;
}

const Input: React.FC<InputProps> = ({
    error,
    helperText,
    label,
    labelAlign,
    leftAddon,
    leftElement,
    rightAddon,
    rightElement,
    ...props
}) => {
    const theme = useTheme();

    return (
        <FormControl isInvalid={!!error}>
            {label && (
                <FormLabel
                    textAlign={labelAlign ??'left'}
                    color='gray.500'
                    fontSize='14px'
                    fontWeight={500}
                    htmlFor={props.id}
                    lineHeight={1.43}
                    mb='4px'>
                    {label}
                </FormLabel>
            )}

            <InputGroup>
                {leftAddon && (
                    <InputLeftAddon
                        borderBottomLeftRadius='50px'
                        borderColor='gray.300'
                        borderStyle='1px'
                        borderTopLeftRadius='50px'
                        borderWidth='1px'
                        py='24px'
                        {...leftAddon} />
                )}

                { leftElement && <InputLeftElement {...leftElement} /> }

                <ChakraInput
                    autoComplete='chrome-off'
                    bgColor='gray.50'
                    borderColor='gray.300'
                    borderRadius='10px'
                    borderStyle='1px'
                    borderWidth='1px'
                    color='primary'
                    fontSize='16px'
                    padding='24px 20px'
                    sx={{
                        autocomplete: 'chrome-off'
                    }}
                    _active={{
                        borderColor: 'gray.300',
                        borderWidth: '2px'
                    }}
                    _focus={{
                        borderColor: 'gray.300',
                        borderWidth: '2px'
                    }}
                    _invalid={{
                        borderColor: 'error.300',
                        boxShadow: `0 0 0 2px ${theme.colors.error['100']}`
                    }}
                    _placeholder={{ color: 'gray.500' }}
                    {...props}/>

                {rightElement && <InputRightElement {...rightElement} /> }

                { rightAddon && <InputRightAddon {...rightAddon} /> }
            </InputGroup>

            {helperText && (
                <FormHelperText color='secondary'>
                    {helperText}
                </FormHelperText>
            )}

            {error && (
                <FormErrorMessage color='error.500' fontSize='14px' lineHeight={1.22}>
                    {error}
                </FormErrorMessage>
            )}
        </FormControl>
    );
};

export default Input;