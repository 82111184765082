import { Button, Flex, Text } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useMemo } from 'react';
import { AppRoute } from '../helpers/constants';
import AboutPopover from './AboutPopover';

const Products: React.FC = () => {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();

    const [onTag, onOtc, onMarket] = useMemo(() => [
        pathname === '/',
        pathname === '/swap',
        pathname === '/market'
    ], [pathname]);

    return (
        <Flex gap={3} ml={5} lineHeight={2.4}>
            <Button
                bg='transparent'
                _hover={{ color: 'tagmi.dark' }}
                fontWeight={onTag ? 'bold' : ''}
                onClick={() => navigate(AppRoute.TAG + search)}>
                <Text color={onTag ? 'tagmi.dark' : 'gray.600'}  _hover={{ color: 'tagmi.dark' }}>TAG</Text>
            </Button>
            <Button
                bg='transparent'
                fontWeight={onOtc ? 'bold' : ''}
                onClick={() => navigate(AppRoute.SWAP + search)}>
                <Text color={onOtc ? 'tagmi.dark' : 'gray.600'}  _hover={{ color: 'tagmi.dark' }}>OTC</Text>
            </Button>
            <Button
                bg='transparent'
                fontWeight={onMarket ? 'bold' : ''}
                onClick={() => navigate(AppRoute.MARKET + search)}>
                <Text color={onMarket ? 'tagmi.dark' : 'gray.600'}  _hover={{ color: 'tagmi.dark' }}>MARKET</Text>
            </Button>
            <AboutPopover/>
        </Flex>
    );
}

export default Products;
