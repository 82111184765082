import React, { useMemo, useState } from 'react';
import {
    Button,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Slider,
    SliderFilledTrack,
    SliderMark,
    SliderThumb,
    SliderTrack,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import { MAX_BPS } from '../../../helpers/constants';

const labelStyles = {
    mt: '2',
    ml: '-2.5',
    fontSize: 'sm',
}

interface IFeePopoverProps {
    children: any;
    feeBps: number;
    isDefaultArbitrator: boolean;
    onChangeFee: (bps: number) => void;
}

const FeePopover: React.FC<IFeePopoverProps> = ({ feeBps, onChangeFee, isDefaultArbitrator, children }) => {
    const [sliderValue, setSliderValue] = useState(feeBps)
    const { onOpen, onClose, isOpen } = useDisclosure();

    const feeFormatted = useMemo(() => ((sliderValue / MAX_BPS) * 100).toFixed(1), [sliderValue]);

    const onPopOverOpen = (onOpen: any): void => {
        setSliderValue(isDefaultArbitrator ? 500 : feeBps);
        onOpen();
    };


    return (
        <Popover
            placement='top-end'
            isOpen={isOpen}
            onOpen={() => onPopOverOpen(onOpen)}
            onClose={onClose}>
            <PopoverTrigger>
                { children }
            </PopoverTrigger>
            <PopoverContent _focus={{ boxShadow: 'none', border: 'none' }} shadow='xl'>
                <PopoverCloseButton />
                <PopoverHeader>Set Arbitrator fee</PopoverHeader>
                <PopoverBody py={5} px={10} shadow='2xl'>
                    <Text w='100%' textAlign='center' color='red.400' fontSize='lg'>
                        { feeFormatted }%
                    </Text>
                    <Text fontSize='sm' color='red.200'>
                        {isDefaultArbitrator
                            && 'Arbitrator fee for default wallet address is fixed. If you want to change the fee, ' +
                            'designate a different wallet address as arbitrator.'
                        }
                        {!isDefaultArbitrator
                            && sliderValue < 200
                            && 'Fee is low! Please make sure the Arbitrator agreed'
                        }
                        { sliderValue > 1000 && 'Fee is high! Please make sure you want to give that much' }
                    </Text>
                    <Slider
                        defaultValue={feeBps}
                        min={0}
                        max={2000}
                        step={50}
                        onChange={setSliderValue}
                        isDisabled={isDefaultArbitrator}>
                        <SliderMark value={0} {...labelStyles}>
                            0%
                        </SliderMark>
                        <SliderMark value={1000} {...labelStyles}>
                            10%
                        </SliderMark>
                        <SliderMark value={2000} {...labelStyles}>
                            20%
                        </SliderMark>
                        <SliderTrack bg='red.100'>
                            <SliderFilledTrack bg='tomato' />
                        </SliderTrack>
                        <SliderThumb boxSize={6} bg='tomato' />
                    </Slider>
                    <Button
                        colorScheme='teal'
                        isDisabled={isDefaultArbitrator}
                        mt={5}
                        onClick={() => {
                            onChangeFee(sliderValue);
                            onClose();
                        }}
                        w='100%'
                        size='sm'>
                        Set fee to {feeFormatted}%
                    </Button>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
};

export default FeePopover;
