import React, { useContext, useEffect, useState } from 'react';
import { appChains, Web3Context } from '../providers/Web3Provider';
import { Box, Button, Flex, Link, Spacer, Stack, Text, useToast } from '@chakra-ui/react';
import moment from 'moment/moment';
import { ethers } from 'ethers';
import { erc20ABI, useNetwork } from 'wagmi';
import { uploadNftDataToIpfs } from '../helpers/uploadMetada';
import generateNftSvg from '../helpers/generateNftSvg';
import { AppRoute, IDepositAsset, IEscrow, IFormattedAsset, ZERO_ADDY } from '../helpers/constants';
import getCurrencyDecimalsAndSymbol from '../helpers/getCurrencyDecimalsAndSymbol';
import { useLocation, useNavigate } from 'react-router-dom';
import formatDt from '../helpers/format/formatDt';
import UserDisplay from '../views/UserDisplay';

interface IEscrowEntryProps {
    chainEscrow: IEscrow;
}

const OpenEscrow: React.FC<IEscrowEntryProps> = ({ chainEscrow }) => {
    const { chain } = useNetwork();
    const toast = useToast();
    const navigate = useNavigate();
    const { search } = useLocation();

    const { walletAddress, signer, contracts: { escrow, nft } } = useContext(Web3Context);

    const [btnLoading, setBtnLoading] = useState<boolean>(false);
    const [isCurrentParty, setIsCurrentParty] = useState<boolean>(false);
    const [forDeposit, setForDeposit] = useState<string>('');
    const [againstDeposit, setAgainstDeposit] = useState<string>('');
    const [tokenAddressA, setTokenAddressA] = useState('');
    const [tokenAddressB, setTokenAddressB] = useState('');

    const isArbitrator = walletAddress === chainEscrow.partyArbitrator;
    const explorerBaseUrl = appChains.find(chn => chn.id === chain?.id)?.blockExplorers?.default.url;

    useEffect(() => {
        if (!walletAddress || !chainEscrow) {
            return;
        }
        setIsCurrentParty(walletAddress === chainEscrow.partyA);
    }, [walletAddress, chainEscrow]);

    const depositToEscrow = async (tagEscrow: IEscrow) => {
        if (!signer || !escrow) {
            return;
        }
        setBtnLoading(true);
        const currencyAddress = tagEscrow.pendingAssetB.currency;
        const currencyAmount = tagEscrow.pendingAssetB.amount;
        const payingWithGas = currencyAddress === ZERO_ADDY;

        const token = new ethers.Contract(currencyAddress, erc20ABI, signer);
        if (!payingWithGas) {
            const allowance = await token.allowance(walletAddress, escrow.address);
            if (allowance < currencyAmount) {
                await (
                    await token.approve(escrow.address, currencyAmount)
                ).wait();
            }
        }
        try {
            const escrowId = tagEscrow.escrowId.toNumber();
            const nftAIpfsUrl = await generateNft(escrowId, true);
            const nftBIpfsUrl = await generateNft(escrowId, false);
            const paymentParams = { value: !payingWithGas ? 0 : currencyAmount };
            const tx = await escrow.depositFunds(escrowId, nftAIpfsUrl, nftBIpfsUrl, { ...paymentParams });
            await tx.wait();
            setBtnLoading(false);
            window.location.reload();
        } catch (e: any) {
            console.log(e)
            toast({
                title: e?.reason ?? e?.data?.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
            setBtnLoading(false);
        }
    };

    const generateNft = async (escrowId: number, forPartyA: boolean): Promise<string> => {
        if (!signer || !escrow) {
            throw new Error('Params not set yet');
        }
        const assetsA = await escrow.escrowAssetsA(escrowId);
        const assetsB = chainEscrow.pendingAssetB;
        const svg = generateNftSvg(
            chainEscrow,
            await getFormattedToken(assetsA),
            await getFormattedToken(assetsB),
            forPartyA
        );
        const blob = new Blob([svg], { type: 'image/svg+xml' });
        return await uploadNftDataToIpfs(
            chainEscrow,
            forPartyA,
            assetsA.currency,
            assetsB.currency,
            blob
        );
    }

    useEffect(() => {
        loadEscrowDetails();
    }, [walletAddress, escrow, signer, chainEscrow]);

    const loadEscrowDetails = async () => {
        if (!escrow || !signer || !chain) {
            return;
        }
        const escrowId = chainEscrow.escrowId;
        const assetsA = await escrow.escrowAssetsA(escrowId);
        const assetsB = chainEscrow.pendingAssetB;
        const currencyA = new ethers.Contract(assetsA.currency, erc20ABI, signer);
        const currencyB = new ethers.Contract(assetsB.currency, erc20ABI, signer);
        const { decimals: decimalsA, symbol: symbolA } = await getCurrencyDecimalsAndSymbol(currencyA, chain);
        const { decimals: decimalsB, symbol: symbolB } = await getCurrencyDecimalsAndSymbol(currencyB, chain);
        const amountA = ethers.utils.formatUnits(`${assetsA.amount}`, decimalsA);
        const amountB = ethers.utils.formatUnits(`${assetsB.amount}`, decimalsB);
        setForDeposit(amountA + '\n' + symbolA);
        setAgainstDeposit(amountB + '\n' + symbolB)
        setTokenAddressA(currencyA.address);
        setTokenAddressB(currencyB.address);
    }

    const getFormattedToken = async (asset: IDepositAsset): Promise<IFormattedAsset> => {
        if (!escrow || !signer || !chain) {
            throw new Error('Signer not connected');
        }
        const tokenContract = new ethers.Contract(asset.currency, erc20ABI, signer);
        const { decimals, symbol } = await getCurrencyDecimalsAndSymbol(tokenContract, chain);
        return {
            symbol,
            address: tokenContract.address,
            decimals,
            amount: asset.amount
        };
    }

    const btnProps = {
        borderRadius: 5,
        bg: 'button.action',
        color: 'white',
        w: 'full',
        _hover: { bg: 'tagmi.logo' },
        _focus: { bg: 'tagmi.logo' },
    };

    const getButton = () => {
        if (!escrow || !nft) {
            return;
        }
        const canDeposit = !isArbitrator && !isCurrentParty;
        return (
            <Button
                {...btnProps}
                isDisabled={!canDeposit}
                isLoading={btnLoading}
                onClick={() => depositToEscrow(chainEscrow)}>
                Accept Challenge
            </Button>
        )
    }

    return (
        <Box w='300px' rounded='xl' overflow='hidden' bg='stefanos.1'>
            <Link
                color='gray.800'
                fontWeight='bold'
                fontSize='xl'
                px={2}
                onClick={() => {
                    const id = chainEscrow.escrowId.toNumber();
                    const chainName = chain?.network === 'homestead' ? '' : `/${chain?.network}`;
                    navigate(`${AppRoute.TAG}tag/${id}${chainName}${search}`,);
                }}>
                TAG#{ chainEscrow.escrowId.toNumber() }
            </Link>

            <Box mt={4}>
                <Flex direction='column'>
                    <Text px={3} fontSize='sm' textAlign='center'>
                        <UserDisplay address={chainEscrow.partyA} format />
                    </Text>
                    <Flex px={3} direction='column' textAlign='center'>
                        <Text>
                            HAS BET <Text color='green.500' as='span'>FOR</Text>
                        </Text>
                        <Text
                            color='green.500'
                            fontWeight='bold'
                            cursor='pointer'
                            _hover={{ textDecoration: 'underline' }}
                            onClick={() => window.open(
                                `${explorerBaseUrl}/token/${tokenAddressA}`,
                                '_blank')
                            }>
                            {forDeposit}
                        </Text>
                    </Flex>

                    <Text w='100%' h='120px' p={3} border='0.4px dashed' borderColor='tagmi.dark' borderRadius='md'>
                        { chainEscrow.description }
                    </Text>

                    <Flex direction='column' textAlign='center'>
                        <Text>
                            BET <Text color='red.500' as='span'>AGAINST</Text> THEM
                        </Text>
                        <Spacer />
                        <Text
                            color='red.500'
                            fontWeight='bold'
                            cursor='pointer'
                            _hover={{ textDecoration: 'underline' }}
                            onClick={() => window.open(
                                `${explorerBaseUrl}/token/${tokenAddressB}`,
                                '_blank')
                            }>
                            {againstDeposit}
                        </Text>
                    </Flex>
                    <Flex>
                        { getButton() }
                    </Flex>
                </Flex>

                <Stack
                    p={2}
                    bg='gray.200'
                    fontSize='xs'
                    direction='column'
                    textAlign='center'
                    w='100%'>
                    <Text>Resolution</Text>
                    <Text color='purple.400' fontWeight='bold' fontSize='md'>
                        {formatDt(moment.unix(chainEscrow.determineTime.toNumber()))}
                    </Text>
                    <Text color='gray.500'>
                        Arbitrated by
                        {chainEscrow.partyArbitrator === walletAddress
                            ? 'YOU'
                            : <UserDisplay address={chainEscrow.partyArbitrator} format /> }
                    </Text>
                    <Text color='gray.500'>
                        {chainEscrow.arbitratorFeeBps.toNumber()/100}% fee
                    </Text>
                </Stack>
            </Box>
        </Box>
    );
}

export default OpenEscrow;
