import { File } from 'nft.storage';
import { create } from 'ipfs-http-client';
import { IEscrow, MAX_BPS } from './constants';

const auth = 'Basic ' + btoa('2DB67FBz6yEpUUnLBeO1qhyr0lN:ca8e55b17ccef8fe4fbc7dd107617325');

const ipfsClient = create({
    headers: {
        authorization: auth,
    },
    url: 'https://ipfs.infura.io:5001/api/v0',
});

const uploadImageToIpfs = async (escrow: any, isSideA: boolean, svg: Blob
): Promise<string> =>
{
    const { escrowId, description } = escrow;
    const fName = `${escrowId}-`
        + (isSideA ? 'A' : 'B')
        + '-'
        + description.replace(' ', '')
        + '.svg';
    const f = new File(
        [svg],
        fName,
        { type: 'image/svg+xml' }
    );
    const added = await ipfsClient.add(f);
    return added.path;
}

const uploadMetadataToIpfs = async (data: any): Promise<string> => {
    const added = await ipfsClient.add(JSON.stringify(data));
    return added.path;
}

const ipfsMetadataJson = (
    escrow: IEscrow,
    isSideA: boolean,
    tokenAddressA: string,
    tokenAddressB: string,
    imageIpfcCid: string
) => {
    const { arbitratorFeeBps, escrowId, description, partyArbitrator, determineTime } = escrow;
    const formattedName = `TAG#${escrowId}: ${description}`;
    const side = isSideA ? 'FOR' : 'AGAINST';
    const arbitratorFee = (arbitratorFeeBps.toNumber() / MAX_BPS) * 100;
    return {
        type: 'object',
        external_url: `https://tagmi.app/tag/${escrowId}`,
        name: formattedName,
        title: formattedName,
        description: `
This NFT assigns the owner the right to the ${side} side of this TAG. Should you win you earn the total of this Tag
minus platform and Arbitrator's fees\n\n
${description}\n
FOR Currency: ${tokenAddressA}\n
AGAINST Currency: ${tokenAddressB}\n
Arbitrator: ${partyArbitrator} (${arbitratorFee}% fee)\n
Resolution Timestamp (UTC): ${determineTime}\n
`,
        properties: {
            name: {
                type: 'string',
                description: formattedName
            },
            image: {
                type: 'string',
                description: `ipfs://${imageIpfcCid}`
            },
        },
        attributes: [],
        image: `ipfs://${imageIpfcCid}`,
    };
}

const uploadNftDataToIpfs = async (
    escrow: IEscrow,
    isSideA: boolean,
    tokenAddressB: string,
    tokenAddressA: string,
    svgBlob: Blob
): Promise<string> => {
    const imgIpfsUrl = await uploadImageToIpfs(escrow, isSideA, svgBlob);
    const jsonData = ipfsMetadataJson(escrow, isSideA, tokenAddressA, tokenAddressB, imgIpfsUrl);
    return await uploadMetadataToIpfs(jsonData)
}

export  {
    uploadNftDataToIpfs,
};
