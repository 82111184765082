import { BigNumber } from 'ethers';

export const ZERO_ADDY = '0x0000000000000000000000000000000000000000';
export const MAX_BPS = 10000;

export interface IDepositAsset {
    currency: string;
    amount: BigNumber;
}

export interface IEscrow {
    escrowId: BigNumber;
    partyA: string;
    partyB: string;
    nftA: BigNumber;
    nftB: BigNumber;
    partyArbitrator: string;
    arbitratorFeeBps: BigNumber;
    description: string;
    createTime: BigNumber;
    determineTime: BigNumber;
    started: boolean;
    closed: boolean
    pendingAssetB: IDepositAsset
    winner: string;
}

export interface IOTCEscrow {
    escrowId: BigNumber;
    partyA: string;
    partyB: string;
    createTime: BigNumber;
    started: boolean;
    closed: boolean
    pendingAssetB: IDepositAsset
}

export interface IFormattedAsset {
    address: string,
    amount: BigNumber | number,
    decimals: number
    symbol: string,
}

export enum AppRoute {
    TAG = '/',
    SWAP = '/swap',
    CONNECT = '/connect',
    MARKET = '/market',
    MISSIONS = '/missions'
}