import React, { useContext, useEffect } from 'react';
import { Center, Flex, Heading, Image } from '@chakra-ui/react';
import { Web3Context } from '../providers/Web3Provider';
import { useNavigate } from 'react-router-dom';
import CenteredViewContainer from '../components/Containers/CenteredViewContainer';
import Logo from '../assets/image/logo.png';
import ConnectWallet from '../components/ConnectWallet';

const Connect: React.FC = () => {
    const { isConnected } = useContext(Web3Context);
    const navigate = useNavigate();

    useEffect(() => {
        if (isConnected) {
            navigate(-1);
        }
    }, [navigate, isConnected])

    return (
        <CenteredViewContainer>
            <Center mt={{ base: 35, sm: 10 }}>
                <Image src={Logo} h='50px' />
            </Center>
            <Flex
                alignItems='center'
                background='white'
                border='1px solid gray.300'
                borderRadius={20}
                direction='column'
                gap='1'
                boxShadow='sm'
                maxW={600}
                minW={350}
                mt='40px'
                p='8px'>
                <Heading as='h4' mb={12} size='md' color='gray.900'>
                    Connect Wallet
                </Heading>
                <ConnectWallet />
            </Flex>
        </CenteredViewContainer>
    )
}

export default Connect;
