import {
    Button,
    Flex,
    Icon,
    Link,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger, Show,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { Link as ILink, useNavigate } from 'react-router-dom';
import { RxDotsHorizontal } from 'react-icons/rx';
import React, { useContext } from 'react';
import { DOCS_URL } from './Footer';
import ReactGA from 'react-ga4';
import { AppRoute } from '../helpers/constants';
import UserDisplay from '../views/UserDisplay';
import { Web3Context } from '../providers/Web3Provider';
import { useNetwork } from 'wagmi';
import StatisticsModal from './Modals/StatisticsModal';

const AboutPopover: React.FC = () => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: statsOpen, onOpen: onStatsOpen, onClose: onStatsClose } = useDisclosure();
    const { walletAddress, isConnected, disconnectWallet, appConfig } = useContext(Web3Context);
    const { chain } = useNetwork();

    return (
        <Popover isOpen={isOpen} onClose={onClose} placement='bottom-end' trigger='hover'>
            <PopoverTrigger>
                <Button color='gray.900' bg='transparent' onMouseEnter={onOpen}>
                    <Icon h='20x' as={RxDotsHorizontal} />
                </Button>
            </PopoverTrigger>
            <PopoverContent w='150px'>
                <PopoverBody>
                    <Show below='sm'>
                        <Flex direction='column'>
                            {walletAddress &&
                                <Flex gap={2} alignItems='center'>
                                    { chain && appConfig[chain.id] &&
                                        <>
                                            <Icon as={appConfig[chain.id].logo} h='15px' w='15px'/>
                                            <Text fontSize='xs' textAlign='center' color='gray.900'>
                                                <UserDisplay address={walletAddress} format />
                                            </Text>
                                        </>
                                    }
                                </Flex>
                            }
                        </Flex>
                    </Show>
                    <Flex px={4} py={2} color='gray.600' textAlign='left' display='column'>
                        <Text _hover={{ color: 'gray.700' }} mb={2}>
                            <ILink
                                to={AppRoute.TAG + '?version=2'}
                                onClick={() => {
                                    ReactGA.event({
                                        category: 'About',
                                        action: 'V2',
                                    });
                                }}>
                                TAG V2
                            </ILink>
                        </Text>
                        <Text _hover={{ color: 'gray.700' }} mb={2}>
                            <ILink
                                to={AppRoute.TAG + '?version=1'}
                                onClick={() => {
                                    ReactGA.event({
                                        category: 'About',
                                        action: 'V1',
                                    });
                                }}>
                                TAG V1
                            </ILink>
                        </Text>
                        <Text cursor='pointer' onClick={onStatsOpen} _hover={{ color: 'gray.700' }} mb={2}>
                            Stats
                        </Text>
                    </Flex>
                    <hr />
                    <Flex px={4} py={2} color='gray.600' textAlign='left' display='column'>
                        <Text _hover={{ color: 'gray.700' }} mb={2}>
                            <Link
                                isExternal
                                href={DOCS_URL}
                                onClick={() => {
                                    ReactGA.event({
                                        category: 'About',
                                        action: 'Docs',
                                    });
                                }}>
                                Docs
                            </Link>
                        </Text>
                        {/*<Text _hover={{ color: 'gray.700' }} mb={2}>*/}
                        {/*    <Link*/}
                        {/*        isExternal*/}
                        {/*        href={TWITTER_URL}*/}
                        {/*        onClick={() => {*/}
                        {/*            ReactGA.event({*/}
                        {/*                category: 'About',*/}
                        {/*                action: 'Twitter',*/}
                        {/*            });*/}
                        {/*        }}>*/}
                        {/*        Twitter*/}
                        {/*    </Link>*/}
                        {/*</Text>*/}
                    </Flex>
                    <Show below='sm'>
                        <Flex direction='column'>
                            {isConnected &&
                                <Button
                                    bg='red.400'
                                    borderRadius={0}
                                    color='white'
                                    onClick={disconnectWallet}
                                    size='xs'
                                    w='full'>
                                    Disconnect
                                </Button>
                            }
                            {!isConnected &&
                                <Button
                                    borderRadius='10px'
                                    colorScheme='blue'
                                    width='100%'
                                    p='16px'
                                    onClick={() => navigate(AppRoute.CONNECT)}
                                    size='xs'>
                                    Connect
                                </Button>
                            }
                        </Flex>
                    </Show>
                    <StatisticsModal isOpen={statsOpen} onClose={onStatsClose} />
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}

export default AboutPopover;
